import client from '@/api/client'

/**
 * Retrieves a student by its id
 * @param {string} id The id of the student to retrieve
 * @returns
 */
export async function getStudent ({ studentNumber }) {
  try {
    const response = await client.get(`/aplica/student/${studentNumber}`)
    return response.data.student
  } catch (err) {
    return undefined
  }
}

export default {
  getStudent
}
