<template>
  <section
    style="color: black;"
  >
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :page-data="admissions"
      :always-show-search="true"
      :show-counter="false"
    >
      <template #empty-state>
        <AplicaCurriculumEmptyState>
          <template #description>
            {{ $t("aplica-results.empty-state.curriculum") }}
          </template>
        </AplicaCurriculumEmptyState>
      </template>
      <template #list-skeleton>
        <AplicaCurriculum
          :count="3"
          :count-rows="4"
        />
      </template>
      <template #list>
        <div
          v-for="(admission, index) in curriculum"
          :key="index"
          style="margin-bottom: 5rem;"
        >
          <h2>{{ formatAcademicYear(admission.admissionYear) }}</h2>
          <h5>{{ formatSeason({semester: admission.curricularSemester, season: admission.season}) }}</h5>
          <table
            class="table table-striped"
          >
            <thead>
              <tr>
                <th>{{ $t('aplica-results.headers.course') }}</th>
                <th>{{ $t('aplica-results.headers.degree') }}</th>
                <th>{{ $t('aplica-results.headers.teacher') }}</th>
                <th>{{ $t('aplica-results.headers.examDate') }}</th>
                <th>{{ $t('aplica-results.headers.grade') }}</th>
                <th>{{ $t('aplica-results.headers.school') }}</th>
                <th>{{ $t('aplica-results.headers.comments') }}</th>
                <th>{{ $t('aplica-results.headers.releaseDate') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(course, indexC) in admission.admissions"
                :key="indexC"
              >
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.course') }}
                  </span>
                  {{ course.courseName ? ( course.courseCode + " - " + course.courseName ) : course.courseCode }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.degree') }}
                  </span>
                  {{ course.degree ?? $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.teacher') }}
                  </span>
                  {{ course.teacher ?? $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.examDate') }}
                  </span>
                  {{ course.examDate ? formatDate({dateString: course.examDate}) : $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.grade') }}
                  </span>
                  {{ course.grade ?? $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.school') }}
                  </span>
                  {{ course.school ?? $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.comments') }}
                  </span>
                  {{ course.comments ?? $t('aplica-results.noData') }}
                </td>
                <td>
                  <span
                    aria-hidden="true"
                    class="table__columnheader--mobile"
                  >
                    {{ $t('aplica-results.headers.releaseDate') }}
                  </span>
                  {{ course.releaseDate ? formatDate({dateString: course.releaseDate }) : $t('aplica-results.noData') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </searchpaginatedlist>
  </section>
</template>

<script>
import AplicaCurriculumEmptyState from '@/components/empty-states/AplicaCurriculum.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import AplicaCurriculum from '@/components/loading-skeletons/AplicaCurriculum.vue'
export default {
  components: { AplicaCurriculumEmptyState, SearchPaginatedList, AplicaCurriculum },
  props: {
    admissions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false
    }
  },
  computed: {
    curriculum () {
      const curriculum = []
      const sortedAdmissions = this.admissions.items
      sortedAdmissions.sort(function (a, b) {
        if (a.examDate && b.examDate) return a.examDate - b.examDate
        else if (!a.examDate && !b.releaseDate) return a.releaseDate - b.releaseDate
        else if (!a.examDate) return a.releaseDate - b.examDate
        return a.examDate - b.releaseDate
      })
      for (const admission of this.admissions.items) {
        let added = false

        for (const curr of curriculum) {
          if (curr.admissionYear === admission.admissionYear && curr.curricularSemester === admission.curricularSemester) {
            if (admission.season === 4) {
              const possibleSeason = this.getSeasonGradeImprovement({
                courseCode: admission.courseCode,
                admissions: curr.admissions
              })
              if (possibleSeason !== -1) admission.season = possibleSeason
            }
            if (curr.season === admission.season || (admission.season === 0 && curr.season === 1)) {
              curr.admissions.push(admission)
              added = true
            }
          }
        }
        if (!added) {
          const season = admission.season !== 0 ? admission.season : 1
          curriculum.push({
            admissionYear: admission.admissionYear,
            curricularSemester: admission.curricularSemester,
            season,
            admissions: [admission]
          })
        }
      }
      return curriculum
    }
  },
  mounted () {
    this.isInitialLoading = false
  },
  methods: {
    getSeasonGradeImprovement ({ admissions, courseCode }) {
      const admission = admissions.find(a => a.courseCode === courseCode)
      if (admission) return admission.season + 1
      return -1
    },
    formatDate ({ dateString }) {
      if (dateString == null) return null
      if (typeof dateString === 'number') dateString = dateString.toString()

      const year = dateString.substring(0, 4)

      const month = dateString.substring(4, 6)

      const day = dateString.substring(6, 8)

      return `${day}/${month}/${year}`
    },
    formatAcademicYear (year) {
      if (year === 1999) return '1999/2000'
      const nextYear = parseInt(year.toString().substring(2, 4)) + 1
      return year + '/' + nextYear
    },
    formatSeason ({ semester, season }) {
      if (semester && season) {
        return this.$t(`aplica-results.semester.${semester}`) + ', ' + this.$t(`aplica-results.season.${season}`)
      }
      if (!season) return this.$t(`aplica-results.semester.${semester}`)
      if (!semester) this.$t(`aplica-results.season.${season}`)
      return null
    }
  }
}
</script>
