<template>
  <div
    class="f-field"
    :class="{'f-field--required' : isRequired}"
  >
    <input
      :id="fieldName"
      :name="fieldName"
      :placeholder="placeholder"
      :required="isRequired"
      :inputmode="inputMode"
      class="f-field__input"
      :class="{ 'f-field__input--is-filled': modelValue !== '' }"
      type="text"
      autocomplete=""
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <label
      v-if="showLabel"
      :for="fieldName"
      class="f-field__label"
    >
      {{ label ? label : $t(`fields.${fieldName}.label`) }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      default: null,
      type: String
    },
    fieldName: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    inputMode: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue']
}
</script>
