<template>
  <div
    v-for="x in parseInt(count)"
    :key="x"
    class="card"
  >
    <div class="card-row">
      <h3
        style="margin-bottom: 0;"
        class="loading-text"
      >
        Personal Data
      </h3>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading the student's id</span></li>
        <li><span class="loading-text">Loading the student's name</span></li>
      </ul>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading the student's sex</span></li>
        <li><span class="loading-text">Loading the student's birthdate</span></li>
        <li><span class="loading-text">Loading the student's father's name</span></li>
        <li><span class="loading-text">Loading the student's mother's name</span></li>
        <li><span class="loading-text">Loading the student's birthCode </span></li>
        <li><span class="loading-text">Loading the student's birthParish </span></li>
        <li><span class="loading-text">Loading the student's birthDisctrict </span></li>
        <li><span class="loading-text">Loading the student's birthCounty </span></li>
      </ul>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading the student's addressStreet</span></li>
        <li><span class="loading-text">Loading the student's addressCity</span></li>
        <li><span class="loading-text">Loading the student's doorNumber</span></li>
        <li><span class="loading-text">Loading the student's city</span></li>
      </ul>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading the student's telephoneNumber</span></li>
        <li><span class="loading-text">Loading student's email</span></li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
