<template>
  <div
    class="f-field f-field--checkbox-group"
    :class="{'f-field--required' : isRequired}"
  >
    <div
      :class="{ 'f-field__options--wrap':wrapOptions}"
      @change="emitChanges"
    >
      <div
        v-for="(opt) in options"
        :key="fieldName + opt.value"
        class="f-field--checkbox"
      >
        <input
          :id="`checkbox-list-${fieldName}-${opt.value}`"
          v-model="optionsMap[opt.value]"
          :value="opt.value"
          :checked="optionsMap[opt.value]"
          class="f-field__input"
          type="checkbox"
        >
        <label
          class="f-field__label"
          :for="`checkbox-list-${fieldName}-${opt.value}`"
        > {{ opt.label }} </label>
      </div>
    </div>
    <label
      v-if="showLabel"
      :for="fieldName"
      class="f-field__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: Array,
      default: null
    },
    fieldName: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    wrapOptions: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      optionsMap: this.options.reduce((acc, type) => {
        acc[type.value] = this.modelValue.includes(type.value)
        return acc
      }, {})
    }
  },
  methods: {
    selectedOptions () {
      return Object.keys(this.optionsMap).filter(key => this.optionsMap[key])
    },
    emitChanges () {
      this.$emit('update:modelValue', this.selectedOptions())
    }
  }
}
</script>
