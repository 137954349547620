<template>
  <div class="page">
    <RouterLink :to="{ name:'BISTManagerSearch' }">
      <span class="i-back-arrow" />
      <h6 style="margin: 0;display: inline-block;">
        {{ $t("new-search") }}
      </h6>
    </RouterLink>
    <h1 style="margin-top: 1rem;">
      {{ $t('bist-manager.update') }}
    </h1>
    <template v-if="!formData">
      <SearchEmptyState
        :show-button="true"
        :search-route-name="'BISTManagerSearch'"
      >
        <template #title>
          {{ $t("bist-manager.search-results.update.empty-state.header", {id: id}) }}
        </template>
        <template #button>
          {{ $t("bist-manager.search-results.empty-state.button") }}
        </template>
      </SearchEmptyState>
    </template>
    <div v-else>
      <div class="card">
        <div class="card-row">
          <ul>
            <li>
              <span class="search-results__label">{{ $t('bist-manager.search-results.id') }}</span>{{ formData.id? formData.id : "N/A" }}
            </li>
            <li>
              <span class="search-results__label">{{ $t('bist-manager.search-results.date') }}</span>{{ formData.creationDate? formatDate(formData.creationDate) : "N/A" }}
            </li>
            <li>
              <span class="search-results__label">{{ $t('bist-manager.search-results.uid') }}</span>{{ formData.uid? formData.uid : "N/A" }}
            </li>
          </ul>
        </div>
        <!-- Title -->
        <div class="card-row">
          <TextInputField
            v-model="formData.title"
            :is-required="true"
            field-name="bist-manager-register-title"
            :placeholder="$t('bist-manager.fields.title.placeholder')"
            :show-label="true"
            :label="$t('bist-manager.fields.title.label')"
            @input="validate($event)"
          />
        </div>
        <!-- Authors -->
        <div class="card-row">
          <TextInputField
            v-model="formData.author"
            :is-required="true"
            field-name="bist-manager-register-authors"
            :placeholder="$t('bist-manager.fields.authors.placeholder')"
            :show-label="true"
            :label="$t('bist-manager.fields.authors.label')"
            @input="validate($event)"
          />
        </div>
        <!-- Provenance -->
        <div class="card-row">
          <TextInputField
            v-model="formData.provenance"
            :is-required="false"
            field-name="bist-manager-register-provenance"
            :placeholder="$t('bist-manager.fields.provenance.placeholder')"
            :show-label="true"
            :label="$t('bist-manager.fields.provenance.label')"
            @input="validate($event)"
          />
        </div>
        <!-- Volume -->
        <div class="card-row">
          <TextInputField
            v-model="formData.volume"
            :is-required="false"
            field-name="bist-manager-register-volume"
            :placeholder="$t('bist-manager.fields.volume.placeholder')"
            :show-label="true"
            :label="$t('bist-manager.fields.volume.label')"
            @input="validate($event)"
          />
        </div>
        <div class="card-row--with-columns">
          <!-- Form Of Acquisition -->
          <div class="card-row__column">
            <DropdownField
              v-model="formData.formOfAcquisition"
              :is-required="true"
              field-name="bist-manager-register-formOfAcquisition"
              :show-label="true"
              :label="$t('bist-manager.fields.form-of-acquisition.label')"
              :options="[
                {value:'purchase', label: $t('bist-manager.fields.form-of-acquisition.purchase')},
                {value:'offer',label:$t('bist-manager.fields.form-of-acquisition.offer')}
              ]"
              @input="validate($event)"
            />
          </div>
          <!-- Type of Document -->
          <div class="card-row__column">
            <DropdownField
              v-model="formData.typeOfDocument"
              :is-required="true"
              field-name="bist-manager-register-typeOfDocument"
              :show-label="true"
              :label="$t('bist-manager.fields.type-of-document.label')"
              :options="[
                {value:'book',label:$t('bist-manager.fields.type-of-document.book')},
                {value:'master-or-bologne-teses',label:$t('bist-manager.fields.type-of-document.master-or-bologne-teses')},
                {value:'phd-tesis',label:$t('bist-manager.fields.type-of-document.phd-tesis')},
                {value:'24h-book',label:$t('bist-manager.fields.type-of-document.24h-book')},
                {value:'aggregation-proof',label:$t('bist-manager.fields.type-of-document.aggregation-proof')},
              ]"
              @input="validate($event)"
            />
          </div>
        </div>
        <div class="card-row--with-columns">
          <!-- Library -->
          <div class="card-row__column">
            <DropdownField
              v-model="formData.library"
              :is-required="true"
              field-name="bist-manager-register-library"
              :show-label="true"
              :label="$t('bist-manager.fields.library.label')"
              :options="[
                {value:'alameda',label:$t('bist-manager.fields.library.alameda')},
                {value:'ctn',label:$t('bist-manager.fields.library.ctn')},
                {value:'tagus',label:$t('bist-manager.fields.library.tagus')}
              ]"
              @input="validate($event)"
            />
          </div>
          <!-- Available Formats -->
          <div class="card-row__column">
            <CheckBoxField
              :key="updateKey"
              v-model="formData.formats"
              :is-required="true"
              field-name="bist-manager-register-formats"
              :show-label="true"
              :label="$t('bist-manager.fields.formats.label')"
              :options="[
                {value:'paper',label:$t('bist-manager.fields.formats.paper')},
                {value:'cd',label:$t('bist-manager.fields.formats.cd')},
                {value:'dvd',label:$t('bist-manager.fields.formats.dvd')},
                {value:'other',label:$t('bist-manager.fields.formats.other')},
              ]"
              :wrap-options="true"
              @update:model-value="validate($event)"
            />
          </div>
        </div>
        <!-- Volumes -->
        <div class="card-row">
          <FilesField
            :key="updateKey"
            v-model="formData.volumes"
            field-name="bist-manager-register-volumes"
            :show-label="true"
            :label="$t('bist-manager.fields.volumes.label')"
            :placeholder="$t('bist-manager.fields.volumes.placeholder')"
            :max-file-length="100"
            @force-update="validate($event)"
            @update:model-value="validate($event)"
          />
        </div>
        <!-- comments -->
        <div class="card-row">
          <TextAreaField
            v-model="formData.comments"
            field-name="bist-manager-register-comments"
            :placeholder="$t('bist-manager.fields.comments.placeholder')"
            :show-label="true"
            :editable="true"
            :label="$t('bist-manager.fields.comments.label')"
            @input="validate($event)"
          />
        </div>
      </div>
      <div class="btn-group">
        <button
          class="btn--primary"
          :disabled="!isValid"
          @click="submit()"
        >
          {{ $t("actions.save-changes") }}
        </button>
        <button
          class="btn--light"
          @click="reset()"
        >
          {{ $t("actions.cancel-changes") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInputField from '@/components/utils/fields/TextInputField.vue'
import DropdownField from '@/components/utils/fields/DropdownField.vue'
import CheckBoxField from '@/components/utils/fields/CheckBoxField.vue'
import TextAreaField from '@/components/utils/fields/TextAreaField.vue'
import FilesField from '@/components/utils/fields/Files.vue'
import { useProgressBar, userFeedbackBar } from '@/store'
import { getMonography, updateMonography } from '@/api/bistmanager'
import SearchEmptyState from '@/components/empty-states/Search.vue'

export default {
  components: { TextInputField, DropdownField, CheckBoxField, FilesField, TextAreaField, SearchEmptyState },
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      const { data, status } = await getMonography(this.id)
      if (status === 204) { this.formData = null } else { this.formData = data }
      this.isSearching = false
    }, 500)
    next()
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isSearching: false,
      searchTimeout: undefined,
      formData: {
        title: '',
        author: '',
        provenance: '',
        volume: '',
        formOfAcquisition: '',
        typeOfDocument: '',
        library: '',
        formats: [],
        volumes: [],
        comments: ''
      },
      updateKey: 0,
      isValid: true
    }
  },
  beforeUnmount () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.getData()
  },
  methods: {
    forceUpdate () {
      this.updateKey += 1
    },
    cleanForm () {
      this.getData()
      this.forceUpdate()
    },
    async getData () {
      const { data, status } = await getMonography(this.id)
      if (status === 204) {
        this.formData = null
      } else { this.formData = data }

      this.isInitialLoading = false
      this.forceUpdate()
    },
    async submit () {
      const monography = {
        title: this.formData.title,
        author: this.formData.author,
        provenance: this.formData.provenance,
        volume: this.formData.volume,
        formOfAcquisition: this.formData.formOfAcquisition,
        typeOfDocument: this.formData.typeOfDocument,
        formats: this.formData.formats,
        library: this.formData.library,
        volumes: this.formData.volumes.filter((v) => v.state === 'uploaded'),
        comments: this.formData.comments
      }

      Object.keys(monography).forEach(key => {
        if (typeof monography[key] === 'string' && monography[key].trim().length <= 0) delete monography[key]
      })

      const progressBar = useProgressBar()
      progressBar.setProgressBar(10)
      const response = await updateMonography(this.formData.id, monography)
      if (response.status === 200) {
        progressBar.setProgressBar(40)
        userFeedbackBar().active({ active: true, type: 'info', key: 'bist-manager.update-step.success', dismiss: true })
        this.cleanForm()
      } else {
        userFeedbackBar().active({ active: true, type: 'danger', key: 'bist-manager.update-step.error', dismiss: true })
      }
      progressBar.completeProgressBar()
    },
    reset () {
      const progressBar = useProgressBar()
      progressBar.setProgressBar(10)
      progressBar.setProgressBar(40)
      this.cleanForm()
      progressBar.completeProgressBar()
    },
    formatDate (dateString) {
      if (dateString === undefined) return
      return dateString.substring(0, 10)
    },
    validate (event) {
      this.isValid = true
      const keysToSkip = ['id', 'uid', 'creationDate', 'provenance', 'volume', 'formats', 'volumes', 'comments', 'aid', 'alterationDate']
      Object.keys(this.formData).filter((k) => !keysToSkip.includes(k)).forEach((key) => {
        if (this.formData[key]?.trim().length <= 0) {
          this.isValid = false
        }
      })
      if (!this.isValid) return

      if (this.formData.formats.length < 1) {
        this.isValid = false
        return
      }

      this.formData.volumes.forEach(volume => {
        if (volume.state === 'error') {
          this.isValid = false
        }
      })
    }
  }
}
</script>
