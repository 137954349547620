<template>
  <nav
    v-if="totalPages > 1 || itemsPerPage !== 10"
    class="page-nav"
  >
    <ul
      v-if="totalPages > 1"
      role="navigation"
      class="page-nav__list"
      :class="{ 'page-nav__list--center': centered }"
    >
      <li v-if="currentPage > 1">
        <RouterLink
          :key="$route.url"
          class="page-nav__item page-nav__item--previous"
          :to="{ query: { ...$route.query, page: currentPage - 1 } }"
        >
          {{ $t('previous') }}
        </RouterLink>
      </li>
      <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(1)">
        <RouterLink
          class="page-nav__item"
          :to="{ query: { ...$route.query, page: 1 } }"
        >
          1
        </RouterLink>
      </li>
      <li
        v-if="pagesToShow[0] > 2"
        class="page-nav__ellipsis"
      >
        <span>...</span>
      </li>
      <li
        v-for="page in pagesToShow"
        :key="page"
      >
        <RouterLink
          :key="$route.url"
          class="page-nav__item"
          :class="{ 'page-nav__item--current-page': page == currentPage }"
          :to="{ query: { ...$route.query, page } }"
        >
          {{ page }}
        </RouterLink>
      </li>
      <li
        v-if="pagesToShow[pagesToShow.length - 1] < totalPages - 1"
        class="page-nav__ellipsis"
      >
        <span>...</span>
      </li>
      <li v-if="pagesToShow.length > 0 && !pagesToShow.includes(totalPages)">
        <RouterLink
          class="page-nav__item"
          :to="{ query: { ...$route.query, page: totalPages } }"
        >
          {{ totalPages }}
        </RouterLink>
      </li>
      <li v-if="currentPage < totalPages">
        <RouterLink
          class="page-nav__item page-nav__item--next"
          :to="{ query: { ...$route.query, page: parseInt(currentPage) + 1 } }"
        >
          {{ $t('next') }}
        </RouterLink>
      </li>
    </ul>
    <div
      class="page-nav__page-size"
    >
      <p>{{ $t('entries') }}</p>
      <Dropdown
        size="md"
        class="dropdown--with-check"
      >
        <template #dropdown-trigger>
          <button
            class="dropdown__trigger"
          >
            <p>{{ itemsPerPage }}</p>
            <svg
              width="8"
              height="5"
              viewBox="0 0 8 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.72737 0.843516C1.47703 0.593177 1.07115 0.593177 0.820811 0.843516C0.570472 1.09386 0.570472 1.49974 0.820811 1.75008L3.41391 4.34317C3.44084 4.38883 3.47391 4.43186 3.51313 4.47107C3.64194 4.59989 3.81194 4.66242 3.98075 4.65867C4.14955 4.66242 4.31955 4.59989 4.44837 4.47107C4.48758 4.43186 4.52065 4.38883 4.54758 4.34317L7.14068 1.75008C7.39102 1.49974 7.39102 1.09386 7.14068 0.843516C6.89034 0.593177 6.48446 0.593177 6.23412 0.843516L3.98075 3.09689L1.72737 0.843516Z"
                fill="#45555F"
              />
            </svg>
          </button>
        </template>
        <template #dropdown-panel>
          <ul class="dropdown-menu">
            <li
              class="dropdown-menu__item"
            >
              <router-link
                :class="{'dropdown-menu__link--selected': itemsPerPage === 10}"
                class="dropdown-menu__link"
                :to="{ query: { ...$route.query, page: Math.round(currentPage * (itemsPerPage / 10) - itemsPerPage / 10), perPage: 10 } }"
              >
                <span class="link-text">10</span>
              </router-link>
            </li>
            <li
              class="dropdown-menu__item"
            >
              <router-link
                :class="{'dropdown-menu__link--selected': itemsPerPage === 50}"
                class="dropdown-menu__link"
                :to="{ query: { ...$route.query, page: Math.round(currentPage * (itemsPerPage / 50) - itemsPerPage / 50), perPage: 50 } }"
              >
                <span class="link-text">50</span>
              </router-link>
            </li>
            <li
              class="dropdown-menu__item"
            >
              <router-link
                :class="{'dropdown-menu__link--selected': itemsPerPage === 100}"
                class="dropdown-menu__link"
                :to="{ query: { ...$route.query, page: Math.round(currentPage * (itemsPerPage / 100)), perPage: 100 } }"
              >
                <span class="link-text">100</span>
              </router-link>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>
  </nav>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue'

export default {
  components: {
    Dropdown
  },
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    totalItems: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      default: 1
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    pagesToShow () {
      let from = this.currentPage - this.offset
      if (from < 1) {
        from = 1
      }
      let to = from + this.offset * 2
      if (to >= this.totalPages) {
        to = this.totalPages
      }
      const pagesArray = []
      for (let page = from; page <= to; page++) {
        pagesArray.push(page)
      }
      return pagesArray
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";
.page-nav {
  width: 100%;
  margin-top: 3.5rem;
}
.page-nav__list {
  display: flex;
  justify-content: flex-start;
  margin: 0 -0.25rem;
}
.page-nav__list--center {
  justify-content: center;
}
.page-nav__item,
.page-nav__ellipsis {
  display: inline-block;
  outline: none;
  appearance: none;
  line-height: 1.2;
  font-weight: 400;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  font-size: 0.875rem;
}
.page-nav__item {
  border-width: 0.0625rem;
  border-style: solid;
  border-radius: 0.125rem;
  transition-property: all;
  @include md-transition;

  background-color: transparent;
  border-color: $light-gray;
  color: $dark-400;

  &:hover,
  &:focus {
    background-color: $blue-600;
    border-color: $blue-600;
    color: white;
  }
}
.page-nav__item--previous::before {
  content: "\00ab ";
}
.page-nav__item--next::after {
  content: " \00bb";
}
.page-nav__item--current-page {
  background-color: $blue;
  border-color: $blue;
  color: white;
}
.page-nav__page-size {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  gap: 1.5rem;
  color: $dark-400;
  svg {
    margin-left: 0.5rem;
  }
}
.dropdown--with-check {
  .dropdown__trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $light-gray;
    padding: 0.5rem 1rem;
  }
  .dropdown-menu__link {
    font-size: 0.875rem;
    color: $dark-400;
  }
}
</style>
