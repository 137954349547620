import { fetchProfile } from '@/api/profile'
import { defineStore, mapStores, mapState, mapActions } from 'pinia'

export const useProfileStore = defineStore('profile', {
  state: () => ({
    profile: {}
  }),
  actions: {
    async fetchProfile () {
      this.profile = await fetchProfile()
      return this.profile
    }
  }
})

export const useProgressBar = defineStore('progressBar', {
  state: () => ({
    progressBar: 0
  }),
  actions: {
    setProgressBar (progress) {
      this.progressBar = progress
    },
    completeProgressBar () {
      this.progressBar = 100
    }
  }
})

export const userFeedbackBar = defineStore('feedbackBar', {
  state: () => ({
    topMessage: {
      active: false,
      type: '',
      key: '',
      dismiss: true,
      data: null
    }
  }),
  actions: {
    active (topMessage) {
      this.topMessage = topMessage
    },
    dismiss () {
      this.topMessage = { active: false }
    }
  },
  mutations: {}
})

export default {
  computed: {
    ...mapStores(useProfileStore, useProgressBar, userFeedbackBar),
    ...mapState(useProgressBar, ['progressBar']),
    ...mapState(useProfileStore, ['profile']),
    ...mapState(userFeedbackBar, ['topMessage'])
  },
  methods: {
    ...mapActions(useProgressBar, ['completeProgressBar', 'setProgressBar']),
    ...mapActions(useProfileStore, ['fetchProfile']),
    ...mapActions(userFeedbackBar, ['active', 'dismiss'])
  }
}
