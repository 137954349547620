<template>
  <ul class="card card-list">
    <li
      v-for="user in users"
      :key="user.id"
      class="card-row"
    >
      <UserAvatar
        :name="user.name"
        :src="`https://fenix.tecnico.ulisboa.pt/user/photo/${user.username}?s=80`"
        class="card-row__figure figure--letter"
      />
      <div class="card-row__text">
        <h2 class="card-row__title h5--ssp">
          <RouterLink
            :to="{ name: 'AdministrateUserPage', params: { username: user.username } }"
            class="card-row__link"
          >
            {{ user.name }}
          </RouterLink>
        </h2>
        <p>{{ user.username }}</p>
        <ul
          class="l-list l-list--inline"
        >
          <li
            v-for="(role, index) in user.roles"
            :key="role + index"
            class="l-list__item"
            :style="role == 'user'? {'margin-right': 0 } : {}"
          >
            <span
              v-if="role != 'user'"
              class="label u-text-literal"
            >
              {{ $t(`users.roles.${role}.label`) }}
            </span>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>
<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
export default {
  components: {
    UserAvatar
  },
  props: {
    users: {
      type: Array,
      required: true
    }
  }
}
</script>
