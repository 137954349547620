<template>
  <div
    class="f-field f-field--radiogroup f-field--inline"
    :class="{ 'f-field--required' : isRequired }"
  >
    <fieldset role="radiogroup">
      <!-- <div
          v-if="$te(`advancedsearch.fields.${fieldName}.description`)"
          class="f-field__tooltip"
        >
          <Tooltip>
            <p>
              {{ $t(`advancedsearch.fields.${fieldName}.description`) }}
            </p>
          </Tooltip>
        </div> -->
      <div class="f-field__options">
        <div class="f-field--radio f-field--sm">
          <input
            id="true"
            type="radio"
            :name="`radio-${fieldName}`"
            class="f-field__input"
            :value="true"
            @input="$emit('update:modelValue', $event.target.value)"
          >
          <label
            for="true"
            class="f-field__label"
          >{{ trueLabel ? trueLabel : $t(`fields.boolean.true`) }}</label>
        </div>
        <div class="f-field--radio f-field--sm">
          <input
            id="false"
            type="radio"
            :name="`radio-${fieldName}`"
            :value="false"
            :checked="!modelValue"
            class="f-field__input"
            @input="$emit('update:modelValue', $event.target.value)"
          >
          <label
            for="false"
            class="f-field__label"
          >{{ falseLabel? falseLabel : $t(`fields.boolean.false`) }}</label>
        </div>
      </div>
    </fieldset>
    <label
      v-if="showLabel"
      :for="fieldName"
      class="f-field__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
// import Tooltip from '@/components/utils/Tooltip.vue'

export default {
  // components: {
  //   Tooltip
  // },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    fieldName: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    trueLabel: {
      type: String,
      default: null
    },
    falseLabel: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      checkboxValue: true
    }
  }
}
</script>
