<template>
  <div
    v-for="x in parseInt(count)"
    :key="x"
    style="margin-bottom: 5rem;"
  >
    <h2 class="loading-text">
      AdmissionYear
    </h2>
    <br>
    <h5 class="loading-text">
      curricularSemester, season
    </h5>
    <table class="table table-striped">
      <tr>
        <th class="loading-text">
          course
        </th>
        <th class="loading-text">
          degree
        </th>
        <th class="loading-text">
          teacher
        </th>
        <th class="loading-text">
          examDate
        </th>
        <th class="loading-text">
          grade
        </th>
        <th class="loading-text">
          school
        </th>
        <th class="loading-text">
          comments
        </th>
        <th class="loading-text">
          releaseDate
        </th>
      </tr>
      <tr
        v-for="y in parseInt(countRows)"
        :key="y"
      >
        <td class="loading-text">
          Loading Course Name
        </td>
        <td class="loading-text">
          degree
        </td>
        <td class="loading-text">
          teacher
        </td>
        <td class="loading-text">
          examDate
        </td>
        <td class="loading-text">
          grade
        </td>
        <td class="loading-text">
          school
        </td>
        <td class="loading-text">
          comments
        </td>
        <td class="loading-text">
          releaseDate
        </td>
      </tr>
    </table>
  </div>
</template>
<script>

export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    },
    countRows: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
