import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@/pages/HomePage.vue'
import SpawnerPage from '@/pages/SpawnerPage.vue'
import BISTManagerRegisterPage from '@/pages/BISTManagerRegister.vue'
import BISTManagerSearchPage from '@/pages/BISTManagerSearch.vue'
import BISTManagerSearchResultsPage from '@/pages/BISTManagerResults.vue'
import BISTManagerUpdatePage from '@/pages/BISTManagerUpdate.vue'
import AplicaPage from '@/pages/Aplica.vue'
import AplicaResultsPage from '@/pages/AplicaResults.vue'
import AplicaResultsPersonalDataPage from '@/pages/AplicaResultsPersonalData.vue'
import AplicaResultsStudentDataPage from '@/pages/AplicaResultsStudentData.vue'
import AplicaResultsPaymentsPage from '@/pages/AplicaResultsPayments.vue'
import AplicaResultsCurriculumPage from '@/pages/AplicaResultsCurriculum.vue'
import OCRPage from '@/pages/OCR.vue'
import AdministrateUsersPage from '@/pages/AdministrateUsersPage.vue'
import AdministrateUserPage from '@/pages/AdministrateUserPage.vue'
import AdministrateUsersTabPage from '@/pages/AdministrateUsersTabPage.vue'
import { useProfileStore, useProgressBar, userFeedbackBar } from '@/store'

/** @type {import('vue-router').RouteRecordRaw[]} */
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      app: 'menu'
    }
  },
  {
    path: '/spawner',
    name: 'Spawner',
    component: SpawnerPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      app: 'selfService'
    }
  },
  {
    path: '/bist-manager/register',
    name: 'BISTManagerRegister',
    component: BISTManagerRegisterPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['bist-manager-user']
      },
      app: 'bistmanager',
      routeTo: '/bist-manager/register'
    }
  },
  {
    path: '/bist-manager/update',
    name: 'BISTManagerUpdate',
    component: BISTManagerUpdatePage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['bist-manager-user']
      },
      app: 'bistmanager',
      routeTo: '/bist-manager/search'
    },
    props: route => ({ id: Number(route.query.id) })
  },
  {
    path: '/bist-manager/search',
    name: 'BISTManagerSearch',
    component: BISTManagerSearchPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['bist-manager-user']
      },
      app: 'bistmanager',
      routeTo: '/bist-manager/search'
    }
  },
  {
    path: '/bist-manager/searchResults',
    name: 'BISTManagerSearchResults',
    component: BISTManagerSearchResultsPage,
    props: route => ({
      id: Number(route.query.id) || null,
      title: route.query.title,
      author: route.query.author,
      library: route.query.library,
      typeOfDocument: route.query.typeOfDocument,
      start: route.query.start,
      end: route.query.end,
      page: route.query.page,
      perPage: route.query.perPage
    }),
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['bist-manager-user']
      },
      app: 'bistmanager',
      routeTo: '/bist-manager/search'
    }
  },
  {
    path: '/aplica',
    name: 'Aplica',
    component: AplicaPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['aplica-user']
      },
      app: 'aplica',
      routeTo: '/aplica'
    }
  },
  {
    path: '/aplica-results/:studentId',
    name: 'AplicaResults',
    component: AplicaResultsPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['aplica-user']
      },
      scrollBehavior: {
        routeUpdate: 'savedPosition'
      },
      app: 'aplica',
      routeTo: '/aplica'
    },
    children: [
      {
        path: 'personalData',
        name: 'AplicaResultsPersonalData',
        component: AplicaResultsPersonalDataPage
      },
      {
        path: 'studentData',
        name: 'AplicaResultsStudentData',
        component: AplicaResultsStudentDataPage
      },
      {
        path: 'curriculum',
        name: 'AplicaResultsCurriculum',
        component: AplicaResultsCurriculumPage
      },
      {
        path: 'payments',
        name: 'AplicaResultsPayments',
        component: AplicaResultsPaymentsPage
      }
    ]
  },
  {
    path: '/ocr',
    name: 'OCR',
    component: OCRPage,
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['ocr-user']
      },
      app: 'ocr'
    }
  },
  {
    path: '/users',
    name: 'AdministrateUsersPage',
    redirect: { name: 'AdministrateUsersUserRolePage' },
    component: AdministrateUsersPage,
    props: route => ({
      q: route.query.q,
      page: route.query.page,
      perPage: route.query.perPage
    }),
    meta: {
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['admin']
      },
      app: 'menu'
    },
    children: [
      {
        path: 'all',
        name: 'AdministrateUsersUserRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'bist',
        name: 'AdministrateUsersBISTRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['bist-manager-user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'ocr',
        name: 'AdministrateUsersOCRRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['ocr-user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'aplica',
        name: 'AdministrateUsersAplicaRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['aplica-user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      }, {
        path: 'self-service',
        name: 'AdministrateUsersSelfServiceRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['self-service-user'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      },
      {
        path: 'administrators',
        name: 'AdministrateUsersAdminRolePage',
        component: AdministrateUsersTabPage,
        meta: {
          roles: ['admin'],
          scrollBehavior: {
            routeUpdate: 'savedPosition'
          }
        }
      }
    ]
  },
  {
    path: '/users/:username',
    name: 'AdministrateUserPage',
    component: AdministrateUserPage,
    meta: {
      app: 'menu',
      layout: 'PageWithNavBarAndFooterLayout',
      'porg-auth': {
        roles: ['admin']
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach(async (to, from, next) => {
  const profileStore = useProfileStore()
  const progressBar = useProgressBar()
  userFeedbackBar().dismiss()
  progressBar.setProgressBar(10)
  await profileStore.fetchProfile()
  progressBar.setProgressBar(40)
  return next()
})

router.afterEach((to, from) => {
  const progressBar = useProgressBar()
  progressBar.completeProgressBar()
})

export default router
