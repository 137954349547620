import client from '@/api/client'

export async function uploadMonographyFile ({ formData }) {
  const response = await client.post('/file-upload-handlers/monographys', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export default {
  uploadMonographyFile
}
