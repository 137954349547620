import client from '@/api/client'

/**
 * Adds a monography
 * @param {object} payload An object with all data about the monography
 */
export async function addMonography (payload) {
  const response = await client.post('/bistmanager', payload, {
    validateStatus: function (status) {
      if (status === 500) return true
      if (status === 200) return true
    }
  })
  return response
}

/**
 * Finds monographs based on specified search criteria.
 * @param {object} options An object with search parameters for filtering the monographys.
 * @returns
 */
export async function findMonographys ({ id, title, author, typeOfDocument, library, start, end, skip, limit }) {
  const response = await client.get('/bistmanager', {
    params: {
      ...(id && { id }),
      ...(title && { title }),
      ...(author && { author }),
      ...(typeOfDocument && { typeOfDocument }),
      ...(library && { library }),
      ...(start && { start }),
      ...(end && { end }),
      ...(skip && { skip }),
      ...(limit && { limit })
    }
  }, {
    validateStatus: function (status) {
      if (status === 500) return true
    }
  })
  return response.data
}
/**
 * Updates a single monography
 * @param {string} id The id of the monography to update
 * @param {object} payload An object with all data about the monography
 * @returns
 */
export async function updateMonography (id, payload) {
  const response = await client.put(`/bistmanager/${id}`, payload, {
    validateStatus: function (status) {
      if (status === 500) return true
      if (status === 200) return true
    }
  })
  return response
}

/**
 * Retrieves a single monography by its id
 * @param {string} id The id of the monograph to retrieve
 * @returns
 */
export async function getMonography (id) {
  const response = await client.get(`/bistmanager/${id}`, {
    validateStatus: function (status) {
      if (status === 500) return true
      if (status === 200) return true
      if (status === 204) return true
    }
  })
  return response
}

export default {
  addMonography,
  findMonographys,
  updateMonography
}
