<template>
  <div class="page">
    <h1>{{ $t('bist-manager.register') }}</h1>
    <div class="card">
      <div class="card-row">
        <h4 style="margin: 0;">
          {{ $t('bist-manager.new-record-data') }}
        </h4>
      </div>
      <!-- Old Record Boolean -->
      <div class="card-row">
        <BooleanField
          v-model="formData.oldRecord"
          :is-required="true"
          field-name="bist-manager-register-old_record"
          :show-label="true"
          :label="$t('bist-manager.fields.old-record.label')"
          @input="validate($event)"
        />
      </div>
      <!-- Title -->
      <div class="card-row">
        <TextInputField
          v-model="formData.title"
          :is-required="true"
          field-name="bist-manager-register-title"
          :placeholder="$t('bist-manager.fields.title.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.title.label')"
          @input="validate($event)"
        />
      </div>
      <!-- Authors -->
      <div class="card-row">
        <TextInputField
          v-model="formData.author"
          :is-required="true"
          field-name="bist-manager-register-authors"
          :placeholder="$t('bist-manager.fields.authors.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.authors.label')"
          @input="validate($event)"
        />
      </div>
      <!-- Provenance -->
      <div class="card-row">
        <TextInputField
          v-model="formData.provenance"
          :is-required="false"
          field-name="bist-manager-register-provenance"
          :placeholder="$t('bist-manager.fields.provenance.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.provenance.label')"
          @input="validate($event)"
        />
      </div>
      <!-- Volume -->
      <div class="card-row">
        <TextInputField
          v-model="formData.volume"
          :is-required="false"
          field-name="bist-manager-register-volume"
          :placeholder="$t('bist-manager.fields.volume.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.volume.label')"
          @input="validate($event)"
        />
      </div>
      <div class="card-row--with-columns">
        <!-- Form Of Acquisition -->
        <div class="card-row__column">
          <DropdownField
            v-model="formData.formOfAcquisition"
            :is-required="true"
            field-name="bist-manager-register-formOfAcquisition"
            :show-label="true"
            :label="$t('bist-manager.fields.form-of-acquisition.label')"
            :options="[
              {value:'purchase', label: $t('bist-manager.fields.form-of-acquisition.purchase')},
              {value:'offer',label:$t('bist-manager.fields.form-of-acquisition.offer')}
            ]"
            @input="validate($event)"
          />
        </div>
        <!-- Type of Document -->
        <div class="card-row__column">
          <DropdownField
            v-model="formData.typeOfDocument"
            :is-required="true"
            field-name="bist-manager-register-typeOfDocument"
            :show-label="true"
            :label="$t('bist-manager.fields.type-of-document.label')"
            :options="[
              {value:'book',label:$t('bist-manager.fields.type-of-document.book')},
              {value:'master-or-bologne-teses',label:$t('bist-manager.fields.type-of-document.master-or-bologne-teses')},
              {value:'phd-tesis',label:$t('bist-manager.fields.type-of-document.phd-tesis')},
              {value:'24h-book',label:$t('bist-manager.fields.type-of-document.24h-book')},
              {value:'aggregation-proof',label:$t('bist-manager.fields.type-of-document.aggregation-proof')},
            ]"
            @input="validate($event)"
          />
        </div>
      </div>
      <div class="card-row--with-columns">
        <!-- Library -->
        <div class="card-row__column">
          <DropdownField
            v-model="formData.library"
            :is-required="true"
            field-name="bist-manager-register-library"
            :show-label="true"
            :label="$t('bist-manager.fields.library.label')"
            :options="[
              {value:'alameda',label:$t('bist-manager.fields.library.alameda')},
              {value:'ctn',label:$t('bist-manager.fields.library.ctn')},
              {value:'tagus',label:$t('bist-manager.fields.library.tagus')}
            ]"
            @input="validate($event)"
          />
        </div>
        <!-- Available Formats -->
        <div class="card-row__column">
          <CheckBoxField
            :key="updateKey"
            v-model="formData.formats"
            :is-required="true"
            field-name="bist-manager-register-formats"
            :show-label="true"
            :label="$t('bist-manager.fields.formats.label')"
            :options="[
              {value:'paper',label:$t('bist-manager.fields.formats.paper')},
              {value:'cd',label:$t('bist-manager.fields.formats.cd')},
              {value:'dvd',label:$t('bist-manager.fields.formats.dvd')},
              {value:'other',label:$t('bist-manager.fields.formats.other')},
            ]"
            :wrap-options="true"
            @update:model-value="validate($event)"
          />
        </div>
      </div>
      <!-- Volumes -->
      <div class="card-row">
        <FilesField
          :key="updateKey"
          v-model="formData.volumes"
          field-name="bist-manager-register-volumes"
          :show-label="true"
          :label="$t('bist-manager.fields.volumes.label')"
          :placeholder="$t('bist-manager.fields.volumes.placeholder')"
          :max-file-length="100"
          @force-update="validate($event)"
          @update:model-value="validate($event)"
        />
      </div>
      <!-- comments -->
      <div class="card-row">
        <TextAreaField
          v-model="formData.comments"
          field-name="bist-manager-register-comments"
          :placeholder="$t('bist-manager.fields.comments.placeholder')"
          :show-label="true"
          :editable="true"
          :label="$t('bist-manager.fields.comments.label')"
          @input="validate($event)"
        />
      </div>
    </div>
    <div class="btn-group">
      <button
        class="btn--primary"
        :disabled="!isValid"
        @click="submit()"
      >
        {{ $t("bist-manager.create-record") }}
      </button>
      <button
        class="btn--light"
        @click="reset()"
      >
        {{ $t("bist-manager.clean-form") }}
      </button>
    </div>
  </div>
</template>

<script>
import TextInputField from '@/components/utils/fields/TextInputField.vue'
import BooleanField from '@/components/utils/fields/BooleanField.vue'
import DropdownField from '@/components/utils/fields/DropdownField.vue'
import CheckBoxField from '@/components/utils/fields/CheckBoxField.vue'
import TextAreaField from '@/components/utils/fields/TextAreaField.vue'
import FilesField from '@/components/utils/fields/Files.vue'
import { addMonography } from '@/api/bistmanager'
import store from '@/store'

export default {
  components: { TextInputField, BooleanField, DropdownField, CheckBoxField, FilesField, TextAreaField },
  mixins: [store],
  data () {
    return {
      formData: {
        oldRecord: false,
        title: '',
        author: '',
        provenance: '',
        volume: '',
        formOfAcquisition: '',
        typeOfDocument: '',
        library: '',
        formats: ['paper'],
        volumes: [],
        comments: ''
      },
      updateKey: 0,
      isValid: false
    }
  },
  methods: {
    forceUpdate () {
      this.updateKey += 1
    },
    cleanForm () {
      const key = this.updateKey
      Object.assign(this.$data, this.$options.data.apply(this))
      this.updateKey = key
      this.forceUpdate()
    },
    async submit () {
      this.dismiss()

      const monography = {
        title: this.formData.title,
        author: this.formData.author,
        provenance: this.formData.provenance,
        volume: this.formData.volume,
        formOfAcquisition: this.formData.formOfAcquisition,
        typeOfDocument: this.formData.typeOfDocument,
        formats: this.formData.formats,
        library: this.formData.library,
        volumes: this.formData.volumes.filter((v) => v.state === 'uploaded'),
        comments: this.formData.comments
      }

      Object.keys(monography).forEach(key => {
        if (typeof monography[key] === 'string' && monography[key].trim().length <= 0) delete monography[key]
      })

      this.setProgressBar(10)
      const response = await addMonography(monography)
      this.setProgressBar(40)
      if (response.status === 200) {
        this.active({ active: true, type: 'info', key: 'bist-manager.submission-step.success', data: response.data, dismiss: true })
        this.cleanForm()
      } else {
        this.active({ active: true, type: 'danger', key: 'bist-manager.submission-step.error', dismiss: true })
      }
      this.completeProgressBar()
    },
    reset () {
      this.setProgressBar(10)
      this.setProgressBar(40)
      this.cleanForm()
      this.completeProgressBar()
    },
    validate (event) {
      this.isValid = true
      const keysToSkip = ['oldRecord', 'provenance', 'volume', 'formats', 'volumes', 'comments']
      Object.keys(this.formData).filter((k) => !keysToSkip.includes(k)).forEach((key) => {
        if (this.formData[key]?.trim().length <= 0) {
          this.isValid = false
        }
      })
      if (!this.isValid) return

      if (this.formData.formats.length < 1) {
        this.isValid = false
        return
      }

      this.formData.volumes.forEach(volume => {
        if (volume.state === 'error') {
          this.isValid = false
        }
      })
    }
  }
}
</script>
