<template>
  <div class="page">
    <header class="section-header page__header">
      <div class="section-header__text">
        <RouterLink :to="{ name:'BISTManagerSearch' }">
          <span class="i-back-arrow" />
          <h6 style="margin: 0;display: inline-block;">
            {{ $t("new-search") }}
          </h6>
        </RouterLink>
        <h1
          style="margin: 1rem 0 0;"
        >
          {{ $t("bist-manager.search-results.results-for") }}
        </h1>
        <p>
          {{ queryToString() }}
        </p>
      </div>
      <div class="section-header__meta">
        <a
          style="margin-top: 1rem;"
          class="btn--primary"
          :href="exportCsvUrl"
        >
          {{ $t("bist-manager.search-results.export-csv") }}
        </a>
      </div>
    </header>
    <template v-if="monographys?.totalItems === 0">
      <SearchEmptyState
        :show-button="true"
        :search-route-name="'BISTManagerSearch'"
      >
        <template #title>
          {{ $t("bist-manager.search-results.empty-state.header") }}
        </template>
        <template #description>
          {{ $t("bist-manager.search-results.empty-state.description") }}
        </template>
        <template #button>
          {{ $t("bist-manager.search-results.empty-state.button") }}
        </template>
      </SearchEmptyState>
    </template>
    <SearchPaginatedList
      v-else
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :page-data="monographys"
      :always-show-search="true"
      :show-counter="false"
    >
      <template #list-skeleton>
        <Monography
          :count="10"
        />
      </template>
      <template #list>
        <div
          v-for="(monography) in monographys.items"
          :key="monography.id"
          class="card"
        >
          <div class="card-row">
            <h3 style="margin-bottom: 0;">
              {{ monography.title }}
            </h3>

            <button
              style="margin-left: auto;margin-right: 0;"
              class="btn--light btn--small"
              @click="$router.push({ name: 'BISTManagerUpdate', query:{id:monography.id} })
              "
            >
              {{ $t("bist-manager.update") }}
            </button>
          </div>
          <div class="card-row">
            <ul>
              <li>
                <span class="search-results__label">{{ $t('bist-manager.search-results.id') }}</span>{{ monography.id? monography.id : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('bist-manager.search-results.authors') }}</span>{{ monography.author? monography.author : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('bist-manager.search-results.volume') }}</span>{{ monography.volume? monography.volume : "N/A" }}
              </li>
            </ul>
          </div>
          <div class="card-row--with-columns">
            <div class="card-row__column">
              <ul>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.acquisition') }}</span>{{ monography.formOfAcquisition? $t(`bist-manager.fields.form-of-acquisition.${monography.formOfAcquisition}`) : "N/A" }}
                </li>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.typeOfDocument') }}</span>{{ monography.typeOfDocument? $t(`bist-manager.fields.type-of-document.${monography.typeOfDocument}`) : "N/A" }}
                </li>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.provenance') }}</span>{{ monography.provenance? monography.provenance : "N/A" }}
                </li>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.formats') }}</span>{{ monography.formats? formatsToString(monography.formats) : "N/A" }}
                </li>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.library') }}</span>{{ monography.library? $t(`bist-manager.fields.library.${monography.library}`) : "N/A" }}
                </li>
              </ul>
            </div>
            <div class="card-row__column">
              <ul>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.uid') }}</span>{{ monography.uid? monography.uid : "N/A" }}
                </li>
                <li>
                  <span class="search-results__label">{{ $t('bist-manager.search-results.date') }}</span>{{ monography.creationDate? formatDate(monography.creationDate) : "N/A" }}
                </li>
                <li>
                  <span
                    v-if="monography.comments"
                    class="search-results__label"
                  >{{ $t('bist-manager.search-results.comments') }}</span>{{ monography.comments }}
                </li>
              </ul>
            </div>
          </div>
          <div
            v-if="monography.volumes?.length > 0"
            class="card-row"
          >
            <ul>
              <li
                style="margin-bottom: 1rem;"
              >
                <span
                  class="search-results__label"
                >{{ $t('bist-manager.fields.volumes.label') }}</span>
              </li>
              <li
                v-for="file in monography.volumes"
                :key="file.id"
                class="download-file"
              >
                <span class="download-file__row">
                  <span class="download-file__link">
                    <component
                      :is="file.id ? 'a' : 'span'"
                      :href="`/api/bistmanager/${monography.id ? monography.id : null}/file/${file.id}`"
                      target="_blank"
                      :class="{'u-text-blue': file.id, 'u-text-slate': !file.id}"
                      :name="file.name"
                    >
                      {{ file.name }}
                    </component>
                  </span>
                  <span class="u-text-slate">
                    ({{ prettyBytes(file.size) }})
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template #pagination>
        <PaginationBar
          :current-page="currentPage"
          :total-items="monographys.totalItems"
          :items-per-page="perPage"
        />
      </template>
    </SearchPaginatedList>
  </div>
</template>

<script>
import { findMonographys } from '@/api/bistmanager'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import PaginationBar from '@/components/utils/PaginationBar.vue'
import Monography from '@/components/loading-skeletons/Monography.vue'
import SearchEmptyState from '@/components/empty-states/Search.vue'

export default {
  components: {
    SearchPaginatedList,
    Monography,
    PaginationBar,
    SearchEmptyState
  },
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.currentPage = Number(to.query.page) || 1

      this.monographys = await findMonographys({
        id: this.id,
        title: this.title,
        author: this.author,
        typeOfDocument: this.typeOfDocument,
        library: this.library,
        start: this.start,
        end: this.end,
        skip: (this.perPage * (this.currentPage - 1)),
        limit: this.perPage
      })
      this.isSearching = false
    }, 500)
    next()
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    author: {
      type: String,
      default: null
    },
    library: {
      type: String,
      default: null
    },
    typeOfDocument: {
      type: String,
      default: null
    },
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    perPage: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      monographys: undefined,
      searchTimeout: undefined
    }
  },
  computed: {
    prettyBytes () {
      return (num) => {
        if (typeof num !== 'number' || isNaN(num)) {
          throw new TypeError('Expected a number')
        }

        const neg = num < 0
        const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        if (neg) {
          num = -num
        }

        if (num < 1) {
          return (neg ? '-' : '') + num + ' B'
        }

        const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
        num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
        const unit = units[exponent]

        return (neg ? '-' : '') + num + ' ' + unit
      }
    },
    exportCsvUrl () {
      return '/api/bistmanager/export?' +
      (this.id ? `id=${this.id}&` : '') +
      (this.title ? `title=${this.title}&` : '') +
      (this.author ? `author=${this.author}&` : '') +
      (this.library ? `library=${this.library}&` : '') +
      (this.typeOfDocument ? `typeOfDocument=${this.typeOfDocument}&` : '') +
      (this.start ? `start=${this.start}&` : '') +
      (this.end ? `end=${this.end}` : '')
    }
  },
  beforeUnmount () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.monographys = await findMonographys({
      id: this.id,
      title: this.title,
      author: this.author,
      typeOfDocument: this.typeOfDocument,
      library: this.library,
      start: this.start,
      end: this.end,
      skip: (this.perPage * (this.currentPage - 1)),
      limit: this.perPage
    })

    this.isInitialLoading = false
  },
  methods: {
    queryToString () {
      let string = ''
      if (this.id) { string += this.$t('bist-manager.fields.id.label') + ' "' + this.id + '" ' }
      if (this.title) { string += this.$t('bist-manager.fields.title.label') + ' "' + this.title + '" ' }
      if (this.author) { string += this.$t('bist-manager.fields.authors.label') + ' "' + this.author + '" ' }
      if (this.library) { string += this.$t('bist-manager.fields.library.label') + ' "' + this.library + '" ' }
      if (this.typeOfDocument) { string += this.$t('bist-manager.fields.type-of-document.label') + ' "' + this.typeOfDocument + '" ' }
      if (this.start) { string += this.$t('bist-manager.fields.start.label') + ' "' + this.start + '" ' }
      if (this.end) { string += this.$t('bist-manager.fields.end.label') + ' "' + this.end + '" ' }

      string = string.replaceAll('" ', '", ')
      string = string.slice(0, -2)
      return string
    },
    formatDate (dateString) {
      if (dateString === undefined) return
      return dateString.substring(0, 10)
    },
    formatsToString (formats) {
      let string = ''
      formats.forEach(format => {
        string += this.$t(`bist-manager.fields.formats.${format}`) + ', '
      })
      string = string.slice(0, -2)
      return string
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/variables";

.download-file + .download-file {
  margin-top: 1rem;
}
.download-file {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1;
}
.download-file__description {
  display: inline-block;
  margin-top: 0.5rem;
  font-family: $primary-font;
  font-weight: 400;
}

.download-file__link {
  display: inline-block;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-right: 0.25em;
}
.download-file__row {
  display: inline-flex;
  align-items: center;
}
</style>
