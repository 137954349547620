<template>
  <div class="page">
    <h1>{{ $t('bist-manager.search') }}</h1>
    <div class="card">
      <div class="card-row">
        <h4 style="margin: 0;">
          {{ $t('bist-manager.record-data') }}
        </h4>
      </div>
      <!-- Id -->
      <div class="card-row">
        <NumberInputField
          v-model="formData.id"
          field-name="bist-manager-register-id"
          :placeholder="$t('bist-manager.fields.id.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.id.label')"
          :min-value="1"
        />
      </div>
      <!-- Title -->
      <div class="card-row">
        <TextInputField
          v-model="formData.title"
          field-name="bist-manager-register-title"
          :placeholder="$t('bist-manager.fields.title.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.title.label')"
        />
      </div>
      <!-- Authors -->
      <div class="card-row">
        <TextInputField
          v-model="formData.author"
          field-name="bist-manager-register-authors"
          :placeholder="$t('bist-manager.fields.authors.placeholder')"
          :show-label="true"
          :label="$t('bist-manager.fields.authors.label')"
          @input="validate"
        />
      </div>
      <div class="card-row--with-columns">
        <!-- Library -->
        <div class="card-row__column">
          <DropdownField
            v-model="formData.library"
            field-name="bist-manager-register-library"
            :show-label="true"
            :label="$t('bist-manager.fields.library.label')"
            :options="[
              {value:'alameda',label:$t('bist-manager.fields.library.alameda')},
              {value:'ctn',label:$t('bist-manager.fields.library.ctn')},
              {value:'tagus',label:$t('bist-manager.fields.library.tagus')}
            ]"
            @input="validate"
          />
        </div>
        <!-- Type of Document -->
        <div class="card-row__column">
          <DropdownField
            v-model="formData.typeOfDocument"
            field-name="bist-manager-register-typeOfDocument"
            :show-label="true"
            :label="$t('bist-manager.fields.type-of-document.label')"
            :options="[
              {value:'book',label:$t('bist-manager.fields.type-of-document.book')},
              {value:'master-or-bologne-teses',label:$t('bist-manager.fields.type-of-document.master-or-bologne-teses')},
              {value:'phd-tesis',label:$t('bist-manager.fields.type-of-document.phd-tesis')},
              {value:'24h-book',label:$t('bist-manager.fields.type-of-document.24h-book')},
              {value:'aggregation-proof',label:$t('bist-manager.fields.type-of-document.aggregation-proof')},
            ]"
            @input="validate"
          />
        </div>
      </div>
      <div class="card-row--with-columns">
        <!-- Start Date-->
        <div class="card-row__column">
          <DateField
            v-model="formData.start"
            field-name="bist-manager-register-start"
            :show-label="true"
            :label="$t('bist-manager.fields.start.label')"
            @update:model-value="validate($event)"
          />
        </div>
        <!-- End Date -->
        <div class="card-row__column">
          <DateField
            v-model="formData.end"
            field-name="bist-manager-register-end"
            :show-label="true"
            :label="$t('bist-manager.fields.end.label')"
            @update:model-value="validate($event)"
          />
        </div>
      </div>
    </div>
    <div class="btn-group">
      <button
        class="btn--primary"
        :disabled="!isValid || !isEmptyQuery()"
        @click="performSearch()"
      >
        {{ $t("bist-manager.search") }}
      </button>
    </div>
  </div>
</template>

<script>
import TextInputField from '@/components/utils/fields/TextInputField.vue'
import DateField from '@/components/utils/fields/DateField.vue'
import DropdownField from '@/components/utils/fields/DropdownField.vue'
import NumberInputField from '@/components/utils/fields/NumberInputField.vue'

export default {
  components: { TextInputField, DropdownField, DateField, NumberInputField },
  data () {
    return {
      formData: {
        id: null,
        title: '',
        author: '',
        library: '',
        typeOfDocument: '',
        start: null,
        end: null
      },
      isValid: true
    }
  },
  methods: {
    validate (event) {
      this.isValid = true

      this.isValid = !this.formData.start || Object.keys(this.formData.start).length === 3

      this.isValid = this.isValid && (!this.formData.end || Object.keys(this.formData.end).length === 3)
    },
    isEmptyQuery () {
      return this.formData.id || this.formData.title || this.formData.author || this.formData.library || this.formData.typeOfDocument || this.formData.start || this.formData.end
    },
    formatDate (date) {
      return `${date.year}-${date.month}-${date.day}`
    },
    performSearch () {
      if (this.isEmptyQuery()) {
        const query = {}

        if (this.formData.id) query.id = this.formData.id
        if (this.formData.title) query.title = this.formData.title
        if (this.formData.author) query.author = this.formData.author
        if (this.formData.library) query.library = this.formData.library
        if (this.formData.typeOfDocument) query.typeOfDocument = this.formData.typeOfDocument
        if (this.formData.start) query.start = this.formatDate(this.formData.start)
        if (this.formData.end) query.end = this.formatDate(this.formData.end)

        this.$router.push({ name: 'BISTManagerSearchResults', query })
      }
    }
  }
}
</script>
