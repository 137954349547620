<template>
  <div class="page">
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('users.header') }}
        </h1>
        <p>{{ $t('users.description') }}</p>
      </div>
    </header>
    <div class="f-search">
      <button class="f-search__submit" />
      <input
        v-model.trim="searchQuery"
        :placeholder="$t('users.search')"
        type="search"
        class="f-search__input f-search__input--card"
        @keydown.esc.exact.prevent.stop="searchQuery = ''"
      >
    </div>
    <div class="tab-navigation tab-navigation--overflow">
      <ul class="tab-navigation__list">
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersUserRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersUserRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.all') }}
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersBISTRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersBISTRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.bist') }}
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersOCRRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersOCRRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.ocr') }}
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersSelfServiceRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersSelfServiceRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.selfService') }}
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersAplicaRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersAplicaRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.aplica') }}
          </RouterLink>
        </li>
        <li
          class="tab-navigation__list-item"
          :class="{'tab-navigation__list-item--selected': $route.name === 'AdministrateUsersAdminRolePage'}"
        >
          <RouterLink
            class="tab-navigation__link"
            :to="{
              name: 'AdministrateUsersAdminRolePage',
              query: { ...(searchQuery !== '' && { q: searchQuery })},
            }"
          >
            {{ $t('users.tab.admins') }}
          </RouterLink>
        </li>
      </ul>
    </div>
    <Transition
      name="fade"
      mode="out-in"
    >
      <section
        v-if="isSearching || (usersPage && usersPage.totalItems > 0)"
        key="admin-users-ok-or-searching"
      >
        <Transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="isSearching"
            key="admin-users-searching"
          >
            <UserSkeleton
              :count="3"
              :show-roles="true"
              class="card-list"
            />
          </div>
          <RouterView
            v-else-if="usersPage"
            key="admin-users-list"
            :users="usersPage.items"
          />
        </Transition>
        <Transition name="fade">
          <PaginationBar
            v-if="usersPage"
            :current-page="currentPage"
            :total-items="usersPage.totalItems"
            :items-per-page="perPage"
          />
        </Transition>
      </section>
      <section
        v-else
        key="admin-users-empty"
      >
        <PersonEmptyState>
          <template #title>
            <template v-if="q">
              {{ $t('users.empty-state.search-results.header', { query: q }) }}
            </template>
            <template v-else>
              {{ $t('users.empty-state.always-empty.header') }}
            </template>
          </template>
          <template #description>
            <template v-if="q">
              {{ $t('users.empty-state.search-results.description') }}
            </template>
            <template v-else>
              {{ $t('users.empty-state.always-empty.description') }}
            </template>
          </template>
        </PersonEmptyState>
      </section>
    </Transition>
  </div>
</template>
<script>
import PersonEmptyState from '@/components/empty-states/Person.vue'
import PaginationBar from '@/components/utils/PaginationBar.vue'
import UserSkeleton from '@/components/loading-skeletons/User.vue'
import { list as listUsers } from '@/api/users'

export default {
  components: {
    PersonEmptyState,
    UserSkeleton,
    PaginationBar
  },
  beforeRouteUpdate (to, from, next) {
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.currentPage = Number(to.query.page) || 1

      this.usersPage = await listUsers({
        q: this.q,
        page: this.currentPage,
        perPage: this.perPage,
        roles: this.$route.meta.roles
      })

      this.isSearching = false
    }, 500)
    next()
  },
  props: {
    perPage: {
      type: Number,
      default: 10
    },
    page: {
      type: Number,
      default: 1
    },
    q: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      usersPage: undefined,
      searchTimeout: undefined,
      searchQuery: this.q
    }
  },
  watch: {
    searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return
      const routeQ = this.$route.query.q ?? ''
      if (routeQ === newValue) return

      this.isSearching = true
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        const query = { ...this.$route.query, q: newValue }
        if (newValue === '') {
          delete query.q
        }
        delete query.page
        this.$router.replace({ query })
      }, 500)
    }
  },
  beforeUnmount () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.currentPage = Number(this.page) || 1

    this.usersPage = await listUsers({
      q: this.q,
      page: this.currentPage,
      perPage: this.perPage,
      roles: this.$route.meta.roles
    })

    this.isInitialLoading = false
  }
}
</script>
