<template>
  <div class="page">
    <RouterLink :to="{ name:'Aplica' }">
      <span class="i-back-arrow" />
      <h6 style="margin: 0;display: inline-block;">
        {{ $t("new-search") }}
      </h6>
    </RouterLink>
    <template v-if="!isSearching && personalData.totalItems === 0 ">
      <SearchEmptyState
        style="margin: 1rem 0 2.5rem;"
        :show-button="true"
        :search-route-name="'Aplica'"
      >
        <template #title>
          {{ $t("aplica-results.empty-state.student.header", {studentId: $route.params.studentId}) }}
        </template>
        <template #description>
          {{ $t("aplica-results.empty-state.student.description",) }}
        </template>
        <template #button>
          {{ $t("aplica-results.empty-state.student.button",) }}
        </template>
      </SearchEmptyState>
    </template>
    <div v-else-if="!isSearching">
      <h1
        v-if="personalData.items.studentName"
        style="margin: 1rem 0 2.5rem;"
      >
        {{ personalData.items.studentName }}
      </h1>
      <div class="tab-navigation tab-navigation--overflow">
        <ul class="tab-navigation__list">
          <li
            class="tab-navigation__list-item"
            :class="{ 'tab-navigation__list-item--selected': $route.name === 'AplicaResultsPersonalData' }"
          >
            <RouterLink
              :to="{ name:'AplicaResultsPersonalData' }"
              class="tab-navigation__link"
            >
              {{ $t("aplica-results.labels.personalData") }}
            </RouterLink>
          </li>
          <li
            class="tab-navigation__list-item"
            :class="{ 'tab-navigation__list-item--selected': $route.name === 'AplicaResultsStudentData' }"
          >
            <RouterLink
              :to="{ name:'AplicaResultsStudentData' }"
              class="tab-navigation__link"
            >
              {{ $t("aplica-results.labels.studentData") }}
            </RouterLink>
          </li>
          <li
            class="tab-navigation__list-item"
            :class="{ 'tab-navigation__list-item--selected': $route.name === 'AplicaResultsPayments' }"
          >
            <RouterLink
              :to="{ name:'AplicaResultsPayments' }"
              class="tab-navigation__link"
            >
              {{ $t("aplica-results.labels.payments") }}
            </RouterLink>
          </li>
          <li
            class="tab-navigation__list-item"
            :class="{ 'tab-navigation__list-item--selected': $route.name === 'AplicaResultsCurriculum' }"
          >
            <RouterLink
              :to="{ name:'AplicaResultsCurriculum' }"
              class="tab-navigation__link"
            >
              {{ $t("aplica-results.labels.curriculumInformation") }}
            </RouterLink>
          </li>
        </ul>
      </div>
      <RouterView
        :personal-data="personalData"
        :student-data="studentData"
        :payments="payments"
        :admissions="admissions"
      />
    </div>
  </div>
</template>

<script>
import { getStudent } from '@/api/aplica'
import SearchEmptyState from '@/components/empty-states/Search.vue'

export default {
  components: { SearchEmptyState },
  data () {
    return {
      isSearching: true,
      personalData: {
        items: undefined,
        totalItems: 0
      },
      studentData: {
        items: undefined,
        totalItems: 0
      },
      payments: {
        items: undefined,
        totalItems: 0
      },
      admissions: {
        items: undefined,
        totalItems: 0
      }
    }
  },
  async created () {
    await this.search({ studentNumber: this.$route.params.studentId })
  },
  methods: {
    async search ({ studentNumber }) {
      this.isSearching = true
      if (studentNumber === '') {
        return
      }

      let searchResults = JSON.stringify(await getStudent({ studentNumber }))
      if (searchResults != null) {
        searchResults = JSON.parse(searchResults)

        this.personalData.items = searchResults.personalData
        this.personalData.totalItems = searchResults.personalData ? 1 : 0

        this.studentData.items = searchResults.studentData
        this.studentData.totalItems = searchResults.studentData ? 1 : 0

        this.payments.items = this.studentData?.items.payments ?? null
        this.payments.totalItems = this.payments.items ? this.payments.items.length : 0

        this.admissions.items = searchResults.studentData.degrees.degree[0].admissions
        this.admissions.totalItems = searchResults.studentData.degrees.degree[0].admissions.length

        this.$router.push({ name: this.$route.name !== 'AplicaResults' ? this.$route.name : 'AplicaResultsPersonalData' })
      }
      this.isSearching = false
    },
    formatDate ({ dateString }) {
      if (dateString === undefined) return

      const year = dateString.substring(0, 4)

      let month = Number(dateString.substring(4, 6))
      month = this.$t(`months.${month}`)

      const day = dateString.substring(6, 8)

      return `${day} ${month} ${year}`
    },
    formatSex (sexChar) {
      if (sexChar === 'M') return this.$t('aplica-results.sex.masculine')
      if (sexChar === 'F') return this.$t('aplica-results.sex.feminine')
    }
  }
}
</script>
