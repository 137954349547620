<template>
  <main class="search-page layout-centered">
    <header>
      <h1 class="search-page__title">
        {{ $t('aplica.header') }}
      </h1>
    </header>
    <p>
      {{ $t('aplica.searchDescription') }}
    </p>
    <form
      role="search"
      class="f-search"
      @keydown.esc.exact.prevent.stop="searchQuery = ''"
    >
      <button
        type="button"
        class="f-search__submit"
        @click="performSearch(searchQuery)"
      >
        {{ $t('search') }}
      </button>
      <input
        ref="searchInput"
        v-model="searchQuery"
        :placeholder="$t('aplica.searchExample')"
        type="text"
        class="f-search__input f-search__input--card"
        @keydown.enter.prevent="performSearch(searchQuery)"
      >
    </form>
    <p
      class="search-page__bad-format-message"
      :class="{ invisible: !alertBadFormat, }"
    >
      {{ $t('aplica.badFormatMessage') }}
    </p>
  </main>
</template>

<script>
export default {
  data () {
    return {
      alertBadFormat: false,
      searchQuery: '',
      selectedDomain: 'records'
    }
  },
  computed: {
    isEmptyQuery () {
      return !this.searchQuery || this.searchQuery.length === 0
    }
  },
  watch: {
    searchQuery (value) {
      if (this.isEmptyQuery || (!isNaN(value) && value.length > 4)) this.alertBadFormat = false
      else this.alertBadFormat = true
    }
  },
  methods: {
    focusOn (ref) {
      ref.focus()
    },
    performSearch (q) {
      if (!this.isEmptyQuery && !this.alertBadFormat) {
        this.$router.push({ name: 'AplicaResults', params: { studentId: q.trim() } })
      }
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('aplica.title'),
      meta: [{ name: 'description', content: this.$t('aplica.description', { institutionName: this.configuration.institutionName }) }]
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.search-page.layout-centered {
  text-align: center;
  width: 100%;
  max-width: 46rem;
  padding: 5rem 1rem;
  margin: auto;
  @media screen and (width >= 47.5rem) and (height >= 40rem) {
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.search-page__title {
  margin: 1rem 0;
  font-size: 1.6875rem;
  @media screen and (width >= 47.5rem) {
    font-size: 2rem;
  }
}

.search-page__bad-format-message {
  font-size: 0.813rem;
  color: $magenta;
}

.invisible {
  visibility: hidden;
}

</style>
