<template>
  <div
    class="f-field"
    :class="{'f-field--required' : isRequired, 'f-field--danger': error !== null}"
  >
    <label
      v-if="showLabel"
      class="f-field__label"
    >{{ label ? label : $t(`fields.${fieldName}.label`) }}</label>
    <div class="f-inline-group">
      <div class="f-field f-field--select">
        <label
          v-show="!date.day && date.day !== ''"
          class="f-field__label--select"
          :for="`day-${fieldName}`"
        >{{ $t('fields.date.day') }}</label>
        <select
          :id="`day-${fieldName}`"
          v-model="date.day"
          class="f-field__input"
          :style="{'opacity': modelValue == '' ? 0.5 : 1 }"
        >
          <option value="">
            --
          </option>
          <option
            v-for="d in days"
            :key="d"
            :value="d"
          >
            {{ d }}
          </option>
        </select>
      </div>
      <div class="f-field f-field--select">
        <label
          v-show="!date.month && date.month !== ''"
          class="f-field__label--select"
          :for="`month-${fieldName}`"
        >{{ $t('fields.date.month') }}</label>
        <select
          :id="`month-${fieldName}`"
          v-model="date.month"
          class="f-field__input"
          :style="{'opacity': modelValue == '' ? 0.5 : 1 }"
        >
          <option value="">
            --
          </option>
          <option
            v-for="m in 12"
            :key="m"
            :value="m"
          >
            {{ $t(`months.${m}`) }}
          </option>
        </select>
      </div>
      <div class="f-field f-field--select">
        <label
          v-show="!date.year && date.year !== ''"
          class="f-field__label--select"
          :for="`year-${fieldName}`"
        >{{ $t('fields.date.year') }}</label>
        <select
          :id="`year-${fieldName}`"
          v-model="date.year"
          :required="isRequired"
          class="f-field__input"
          :style="{'opacity': modelValue == '' ? 0.5 : 1 }"
        >
          <option
            v-if="!isRequired"
            value=""
          >
            --
          </option>
          <option
            v-for="y in years"
            :key="y"
            :value="y"
          >
            {{ y }}
          </option>
        </select>
      </div>
    </div>
    <Transition name="validation-fade">
      <!-- f-field--danger -->
      <p
        v-if="error !== null"
        class="f-field__validation f-field--danger"
      >
        {{ error.message }}
      </p>
    </Transition>
  </div>
</template>
<script>
import range from 'underscore/modules/range'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        year: '',
        month: '',
        day: ''
      })
    },
    fieldName: {
      type: String,
      required: true
    },
    yearsExtraRange: {
      type: Number,
      default: 2
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    return {
      now: new Date(),
      date: this.value,
      modelValue: null,
      error: null
    }
  },
  computed: {
    years () {
      return range(1500, this.now.getFullYear() + 1 + this.yearsExtraRange, 1).reverse()
    },
    days () {
      if ([1, 3, 5, 7, 8, 10, 12].includes(this.date.month)) {
        return 31
      } else if (this.date.month !== 2) {
        return 30
      } else {
        // Is leap year
        if ((this.date.year % 400 === 0) || ((this.date.year % 100 !== 0) && (this.date.year % 4 === 0))) {
          return 29
        } else {
          return 28
        }
      }
    }
  },
  watch: {
    date: {
      deep: true,
      handler (newDate) {
        this.error = null
        const date = Object.assign({}, newDate)
        if (!date.year) {
          this.error = { message: this.$t('fields.date.date-must-be-complete') }
          delete date.year
        }
        if (!date.month) {
          this.error = { message: this.$t('fields.date.date-must-be-complete') }
          delete date.month
        }
        if (!date.day) {
          this.error = { message: this.$t('fields.date.date-must-be-complete') }
          delete date.day
          if (Object.values(date).every(x => x === '')) this.error = null
        }
        this.modelValue = date
        this.$emit('update:modelValue', date)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.f-field__label--select {
  position: absolute;
  font-size: 1.125rem;
  top: 12px;
  left: 1rem;
  font-weight: 400;
}
</style>
