<template>
  <div
    v-if="store.topMessage.active"
    :class="{ 'feedback-top-bar--informative': store.topMessage.type === 'info', 'feedback-top-bar--danger': store.topMessage.type === 'danger', 'feedback-top-bar--warning': store.topMessage.type === 'warn' }"
    class="feedback-top-bar"
    role="feedbackbar"
  >
    <div class="feedback-top-bar-container container">
      <div class="feedback-top-bar__message">
        <p>{{ $t(store.topMessage.key, store.topMessage.data ) }}</p>
      </div>
      <button
        v-if="store.topMessage.dismiss"
        class="feedback-top-bar__close"
        aria-label="Dismiss this message"
        @click.prevent="store.dismiss()"
      >
        <span class="icon icon-close">
          <svg
            width="18"
            height="18"
            viewBox="0 0 22 22"
            xmlns="http://www.w3.org/2000/svg"
          ><g
            transform="translate(-1 -1)"
            stroke="white"
            stroke-width="2"
            fill="none"
            fill-rule="evenodd"
            class="icon-stroke"
          ><circle
            cx="12"
            cy="12"
            r="10"
          /><path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.429 8.429L15.5 15.5M15.567 8.429L8.496 15.5"
          /></g></svg>
        </span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { userFeedbackBar } from '@/store'
const store = userFeedbackBar()
</script>

<style lang="scss">
@import "@/assets/scss/variables";

.feedback-top-bar {
  position: fixed;
  top: $header-height;
  width: 100%;
  min-height: 44px;
  padding: 0.6rem 0 0.5rem;
  z-index: 1;

  // default state
  background: $dark;
  color: $light-blue-700;
  transition-property: opacity, visibility;
  @include md-transition;

  &.is-visible {
    opacity: 100%;
    visibility: visible;
    pointer-events: all;
  }

  .icon-close:hover .icon-stroke,
  .icon-close:focus .icon-stroke {
    fill: #fff;
    stroke: $dark;
  }
}

// Other types of feedback: Informative
.feedback-top-bar--informative {
  background: $green;
  color: #fff;

  .icon-close:hover .icon-stroke,
  .icon-close:focus .icon-stroke {
    fill: #fff;
    stroke: $green;
  }
}

// Other types of feedback: Warning
.feedback-top-bar--danger {
  background: $magenta-600;
  color: #fff;

  .icon-close:hover .icon-stroke,
  .icon-close:focus .icon-stroke {
    fill: #fff;
    stroke: $magenta-600;
  }
}

// Other types of feedback: Request
.feedback-top-bar--warning {
  background: $orange;
  color: #fff;

  .icon-close:hover .icon-stroke,
  .icon-close:focus .icon-stroke {
    fill: #fff;
    stroke: $orange;
  }
}

.feedback-top-bar p {
  color: #fff;
}

.icon-close .icon-stroke {
  transition-property: stroke, fill;
  @include md-transition;
}

.feedback-top-bar-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.feedback-top-bar__message {
  flex-grow: 1;
  padding-right: 1rem;

  @media screen and (width >= 60rem) {
    padding-right: 4rem;
  }
}
</style>
