<template>
  <section>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :page-data="personalData"
      :always-show-search="true"
      :show-counter="false"
    >
      <template #empty-state>
        <AplicaStudentEmptyState>
          <template #description>
            {{ $t("aplica-results.empty-state.personalData") }}
          </template>
        </AplicaStudentEmptyState>
      </template>
      <template #list-skeleton>
        <AplicaPersonalData
          :count="1"
        />
      </template>
      <template #list>
        <div
          class="card"
        >
          <div class="card-row">
            <h3 style="margin: 0;">
              {{ $t('aplica-results.labels.personalData') }}
            </h3>
          </div>
          <div class="card-row">
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.identification') }}</span>{{ personalData.items.id ? personalData.items.id : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.name') }}</span>{{ personalData.items.studentName ? personalData.items.studentName : "N/A" }}
              </li>
            </ul>
          </div>
          <div class="card-row">
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.sex') }}</span>{{ personalData.items.sex ? formatSex( personalData.items.sex) : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.birthdate') }}</span>{{ personalData.items.birthDate ? formatDate({dateString: personalData.items.birthDate}) : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.fathersName') }}</span>{{ personalData.items.fatherName ? personalData.items.fatherName : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.mothersName') }}</span>{{ personalData.items.motherName ? personalData.items.motherName : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.birthCode') }}</span>{{ personalData.items.birthCode ? personalData.items.birthCode : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.birthParish') }}</span>{{ personalData.items.parishName ? personalData.items.parishName : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.birthCounty') }}</span>{{ personalData.items.countyName ? personalData.items.countyName : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.birthDisctrict') }}</span>{{ personalData.items.disctrictName ? personalData.items.disctrictName : "N/A" }}
              </li>
            </ul>
          </div>
          <div class="card-row">
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.addressStreet') }}</span>{{ personalData.items.addressStreet ? personalData.items.addressStreet : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.addressCity') }}</span>{{ personalData.items.addressCity ? personalData.items.addressCity : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.postalCode') }}</span>{{ personalData.items.doorNumber ? personalData.items.doorNumber : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.postalCity') }}</span>{{ personalData.items.city ? personalData.items.city : "N/A" }}
              </li>
            </ul>
          </div>
          <div
            class="card-row"
          >
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.telephone') }}</span>{{ personalData.items.telephoneNumber ? personalData.items.telephoneNumber : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.email') }}</span>{{ personalData.items.email ? personalData.items.email : "N/A" }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </SearchPaginatedList>
  </section>
</template>

<script>
import AplicaStudentEmptyState from '@/components/empty-states/Search.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import AplicaPersonalData from '@/components/loading-skeletons/AplicaPersonalData.vue'
export default {
  components: { AplicaStudentEmptyState, SearchPaginatedList, AplicaPersonalData },
  props: {
    personalData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false
    }
  },
  mounted () {
    this.isInitialLoading = false
  },
  methods: {
    formatSex (sexChar) {
      if (sexChar === 'M') return this.$t('aplica-results.sex.masculine')
      if (sexChar === 'F') return this.$t('aplica-results.sex.feminine')
    },
    formatDate ({ dateString }) {
      if (dateString === undefined) return

      const year = dateString.substring(0, 4)

      let month = Number(dateString.substring(4, 6))
      month = this.$t(`months.${month}`)

      const day = dateString.substring(6, 8)

      return `${day} ${month} ${year}`
    }
  }
}
</script>
