<template>
  <div
    v-for="x in parseInt(count)"
    :key="x"
    class="card"
  >
    <div class="card-row">
      <h3
        style="margin-bottom: 0;"
        class="loading-text"
      >
        Student Data
      </h3>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading student's id</span></li>
        <li><span class="loading-text">Loading degreeCode</span></li>
        <li><span class="loading-text">Loading field</span></li>
        <li><span class="loading-text">Loading orientation</span></li>
        <li><span class="loading-text">Loading pole</span></li>
        <li><span class="loading-text">Loading admission</span></li>
        <li><span class="loading-text">Loading average</span></li>
        <li><span class="loading-text">Loading degreeEndingDate</span></li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
