<template>
  <div
    class="f-field"
    :class="{'f-field--required' : isRequired}"
  >
    <div
      v-if="files.length < maxFileLength"
      :class="{
        'f-drag-and-drop--file-hover': fileHoveringDragAndDrop,
        'f-drag-and-drop--xs': files.length > 0,
        'f-drag-and-drop--sm': files.length === 0 && editable
      }"
      class="f-field__input f-drag-and-drop f-drag-and-drop--xs"
      style="position: relative; justify-content: left;"
      @dragover.prevent="enteringDropArea($event)"
      @dragleave.prevent="leavingDropArea($event)"
      @drop.prevent="onDrop($event)"
    >
      <div class="f-drag-and-drop__illustration">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="11.25"
            stroke="#45555F"
            stroke-width="1.5"
          />
          <path
            d="M12.0001 16.8889V7.11108"
            stroke="#45555F"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M7.11108 12H16.8889"
            stroke="#45555F"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <label
        v-if="showLabel"
        :for="fieldName"
        class="f-drag-and-drop__label"
      >
        {{ placeholder ? placeholder : $t('fields.file.description') }}
      </label>
      <input
        id="fileinput"
        ref="fileinput"
        multiple
        type="file"
        class="f-drag-and-drop__input  file-input"
        @change="filesSelected($event)"
      >
    </div>
    <div
      v-else
      class="f-field f-drag-and-drop f-drag-and-drop--xs f-drag-and-drop--disabled"
    >
      <p>
        {{ $t('fields.file.maxFilesReached') }}
      </p>
    </div>
    <label
      v-if="showLabel"
      :for="fieldName"
      class="f-field__label"
    >
      {{ label }}
    </label>
    <div
      v-if="files.length > 0"
      class="doc-upload-list"
    >
      <div
        v-for="(file, index) in files"
        :key="`${file.checksum || file.id}`"
        class="card"
      >
        <files-row
          :key="updateKey"
          :file="file"
          @delete="removeFile(index)"
          @validate="validate"
        />
      </div>
    </div>
  </div>
</template>
<script>
import * as UploadsAPI from '@/api/uploads'
// import { fieldValues } from '@scholar/metadata-utils/main'

import sha256 from 'crypto-js/sha256'
import WordArray from 'crypto-js/lib-typedarrays'
import FilesRow from '@/components/utils/fields/FilesRow.vue'

export default {
  components: {
    FilesRow
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    maxFileLength: {
      type: Number,
      default: 5
    },
    fieldName: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'isValid', 'update:modelValue', 'forceUpdate'],
  data () {
    return {
      fileHoveringDragAndDrop: false,
      // fileTypes: fieldValues.fileType,
      // rights: fieldValues.rights,
      files: this.modelValue,
      updateKey: 0
    }
  },
  async created () {
    this.validate()
  },
  methods: {
    forceUpdate () {
      this.updateKey += 1
    },
    enteringDropArea () {
      this.fileHoveringDragAndDrop = true
    },
    leavingDropArea () {
      this.fileHoveringDragAndDrop = false
    },
    onDrop ($event) {
      this.fileHoveringDragAndDrop = false
      if ($event.dataTransfer.items) {
        if (!$event.dataTransfer?.items) {
          return
        }

        // Use DataTransferItemList interface to access the file(s)
        for (const item of $event.dataTransfer.items) {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            this.uploadFile(item.getAsFile())
          }
        }
      }
    },
    async filesSelected ($event) {
      const files = $event.target?.files
      if (files) {
        for (let index = 0; index < files.length; index++) {
          const file = files[index]
          await this.uploadFile(file)
        }
      }
    },
    async uploadFile (file) {
      const fileObject = {
        name: file.name,
        size: file.size,
        state: 'uploading'
      }
      this.files.push(fileObject)
      try {
        const formData = new FormData()
        formData.append('file', file)
        const { fileId } = await UploadsAPI.uploadMonographyFile({ formData })
        fileObject.state = 'uploaded'
        fileObject.id = fileId
      } catch (error) {
        fileObject.state = 'error'
        fileObject.error = { key: error.response.data.key }
        // Force view to update
      } finally {
        this.$emit('input', this.files)
        this.forceUpdate()
        this.$emit('forceUpdate')
      }
    },
    removeFile (index) {
      this.files = this.files.filter((file, i) => i !== index)
      this.validate()
      // allow re-entering the same file
      this.$refs.fileinput.value = null
    },
    validate () {
      this.$emit('input', this.files)
      if (this.files.length > 0) {
        for (const file of this.files) {
          const set = new Set(Object.keys(file))
          if (!(set.has('name') && set.has('size') && set.has('id'))) {
            this.$emit('isValid', false)
            return
          }
        }
      }
      this.$emit('isValid', true)
      this.$emit('update:modelValue', this.files)
    },
    computeSHA256FromBuffer (data) {
      return sha256(WordArray.create(data)).toString()
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/variables";

.doc-upload-list {
  // margin-bottom: 1.5rem;
  order: 2;
}

.f-field__input ~ .doc-upload-list {
  margin-top: 1.5rem;
}

.f-drag-and-drop__input:focus ~ .file-input {
  outline: 2px solid $blue-700;
  outline: 2px solid Highlight; // firefox
  outline: 2px solid -webkit-focus-ring-color; // webkit
}

.file-input {
  opacity: 0%;
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
}
</style>
