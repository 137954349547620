<template>
  <div
    class="empty-state"
  >
    <div class="empty-state__illustration">
      <svg
        width="102"
        height="63"
        viewBox="0 0 102 63"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          transform="translate(2.1 2.153125)"
          stroke="var(--gray)"
          stroke-width="3.2"
          fill="none"
          fill-rule="evenodd"
        >
          <path d="M65.593228 39.268443c3.266294-4.473653 8.614056-7.389426 14.65705-7.389426 9.945283 0 18.007528 7.897392 18.007528 17.63932h-24.84845" />
          <ellipse
            cx="80.250278"
            cy="17.799622"
            rx="10.004182"
            ry="9.799622"
          />
          <path d="M24.83926 49.518336H0c0-9.741927 8.062245-17.639319 18.007528-17.639319 6.040925 0 11.387095 2.913777 14.653695 7.384832" />
          <ellipse
            cx="18.007528"
            cy="17.799622"
            rx="10.004182"
            ry="9.799622"
          />
          <path d="M75.317076 59.082042c0-14.071673-11.726902-25.479017-26.192768-25.479017-14.465867 0-26.192769 11.407344-26.192769 25.479017h52.385537z" />
          <ellipse
            cx="49.124308"
            cy="14.155009"
            rx="14.551538"
            ry="14.155009"
          />
        </g>
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
  </div>
</template>
