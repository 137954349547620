<template>
  <div
    v-if="!isSearching"
    class="page"
  >
    <header class="section-header">
      <div class="section-header__text">
        <p class="h5 section-header__parent-page">
          <RouterLink :to="{ name: 'AdministrateUsersPage' }">
            <span class="i-back-arrow" />
            <span>{{ $t('menu.back') }}</span>
          </RouterLink>
        </p>
        <h1 class="h1 section-header__title">
          {{ user.name }}
        </h1>
      </div>
    </header>
    <div class="card">
      <div class="card-row card-row--column">
        <UserAvatar
          :name="user.name"
          :src="`https://fenix.tecnico.ulisboa.pt/user/photo/${user.username}?s=80`"
          class="card-row__figure figure--letter"
        />
        <div class="card-row__text">
          <p class="card-row__title h5--ssp">
            {{ user.name }}
          </p>
          <p>
            {{ user.username }}
          </p>
          <ul
            v-if="user.roles"
            class="l-list l-list--inline"
          >
            <li
              v-for="role in user.roles"
              :key="role"
              class="l-list__item"
              :style="role == 'user'? {'margin-right': 0 } : {}"
            >
              <span
                v-if="role != 'user'"
                class="label u-text-literal"
              >
                {{ $t(`users.roles.${role}.label`) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <fieldset
      :disabled="submitting"
      class="card"
    >
      <div class="card-row card__header">
        <div class="card-row__text">
          <h2 class="card-row__title h4">
            {{ $t('users.roles.header') }}
          </h2>
        </div>
      </div>
      <div class="card-row">
        <div class="card-row__text">
          <h3 class="card-row__title h5--ssp">
            {{ $t(`users.roles.aplica-user.label`) }}
          </h3>
          <p class="card-row__helper-info card-row__helper-info--mobile">
            {{ $t(`users.roles.aplica-user.description`) }}
          </p>
        </div>
        <div class="card-row__meta">
          <div class="f-field f-field--toggle">
            <input
              id="roleAplica"
              v-model="isAplicaUser"
              type="checkbox"
              class="f-field__input--toggle"
            >
            <div class="f-field__toggle" />
            <label
              for="roleAplica"
              class="sr-only f-field__label"
            >
              {{ $t(`users.roles.aplica-user.label`) }}
            </label>
          </div>
        </div>
      </div>
      <div class="card-row">
        <div class="card-row__text">
          <h3 class="card-row__title h5--ssp">
            {{ $t(`users.roles.bist-manager-user.label`) }}
          </h3>
          <p class="card-row__helper-info card-row__helper-info--mobile">
            {{ $t(`users.roles.bist-manager-user.description`) }}
          </p>
        </div>
        <div class="card-row__meta">
          <div class="f-field f-field--toggle">
            <input
              id="roleBistManager"
              v-model="isBistManagerUser"
              type="checkbox"
              class="f-field__input--toggle"
            >
            <div class="f-field__toggle" />
            <label
              for="roleBistManager"
              class="sr-only f-field__label"
            >
              {{ $t(`users.roles.bist-manager-user.label`) }}
            </label>
          </div>
        </div>
      </div>
      <div class="card-row">
        <div class="card-row__text">
          <h3 class="card-row__title h5--ssp">
            {{ $t(`users.roles.ocr-user.label`) }}
          </h3>
          <p class="card-row__helper-info card-row__helper-info--mobile">
            {{ $t(`users.roles.ocr-user.description`) }}
          </p>
        </div>
        <div class="card-row__meta">
          <div class="f-field f-field--toggle">
            <input
              id="roleOCR"
              v-model="isOCRUser"
              type="checkbox"
              class="f-field__input--toggle"
            >
            <div class="f-field__toggle" />
            <label
              for="roleOCR"
              class="sr-only f-field__label"
            >
              {{ $t(`users.roles.ocr-user.label`) }}
            </label>
          </div>
        </div>
      </div>
      <div class="card-row">
        <div class="card-row__text">
          <h3 class="card-row__title h5--ssp">
            {{ $t(`users.roles.self-service-user.label`) }}
          </h3>
          <p class="card-row__helper-info card-row__helper-info--mobile">
            {{ $t(`users.roles.self-service-user.description`) }}
          </p>
        </div>
        <div class="card-row__meta">
          <div class="f-field f-field--toggle">
            <input
              id="roleSelfService"
              v-model="isSelfServiceUser"
              type="checkbox"
              class="f-field__input--toggle"
            >
            <div class="f-field__toggle" />
            <label
              for="roleSelfService"
              class="sr-only f-field__label"
            >
              {{ $t(`users.roles.self-service-user.label`) }}
            </label>
          </div>
        </div>
      </div>
      <div class="card-row">
        <div class="card-row__text">
          <h3 class="card-row__title h5--ssp">
            {{ $t(`users.roles.admin.label`) }}
          </h3>
          <p class="card-row__helper-info card-row__helper-info--mobile">
            {{ $t(`users.roles.admin.description`) }}
          </p>
        </div>
        <div class="card-row__meta">
          <div class="f-field f-field--toggle">
            <input
              id="roleAdmin"
              v-model="isAdmin"
              type="checkbox"
              class="f-field__input--toggle"
            >
            <div class="f-field__toggle" />
            <label
              for="roleAdmin"
              class="sr-only f-field__label"
            >
              {{ $t(`users.roles.admin.label`) }}
            </label>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import { updateRoles, get as getUser } from '@/api/users'

export default {
  components: {
    UserAvatar
  },
  data () {
    return {
      user: undefined,
      submitting: false,
      isSearching: true
    }
  },
  computed: {
    isAdmin: {
      get () {
        return this.user.roles.includes('admin')
      },
      set (value) {
        if (value) {
          this.addRole('admin')
        } else {
          this.removeRole('admin')
        }
      }
    },
    isAplicaUser: {
      get () {
        return this.user.roles.includes('aplica-user')
      },
      set (value) {
        if (value) {
          this.addRole('aplica-user')
        } else {
          this.removeRole('aplica-user')
        }
      }
    },
    isBistManagerUser: {
      get () {
        return this.user.roles.includes('bist-manager-user')
      },
      set (value) {
        if (value) {
          this.addRole('bist-manager-user')
        } else {
          this.removeRole('bist-manager-user')
        }
      }
    },
    isOCRUser: {
      get () {
        return this.user.roles.includes('ocr-user')
      },
      set (value) {
        if (value) {
          this.addRole('ocr-user')
        } else {
          this.removeRole('ocr-user')
        }
      }
    },
    isSelfServiceUser: {
      get () {
        return this.user.roles.includes('self-service-user')
      },
      set (value) {
        if (value) {
          this.addRole('self-service-user')
        } else {
          this.removeRole('self-service-user')
        }
      }
    }
  },
  async created () {
    this.user = await getUser({ username: this.$route.params.username })
    this.isSearching = false
  },
  methods: {
    async removeRole (role) {
      this.user.roles = this.user.roles.filter(userRole => userRole !== role)
      await this.updateRoles()
    },
    async addRole (role) {
      this.user.roles.push(role)
      await this.updateRoles()
    },
    async updateRoles () {
      // This prevents a user from double clicking on the switch before the previous server request ends
      try {
        this.submitting = true
        this.user = await updateRoles({ username: this.user.username, roles: this.user.roles })
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>
