<template>
  <div class="page">
    <h1 style="margin: 0;">
      {{ $t("home.header") }}
    </h1>
    <p>{{ $t("home.description") }}</p>
    <div class="card">
      <div class="card-row">
        <h4 style="margin: 0;">
          {{ $t("home.header") }}
        </h4>
      </div>
      <RouterLink
        v-if="profile.roles.includes('self-service-user')"
        :to="{name: 'Spawner'}"
        class="card-row card-row__link"
      >
        <div class="card-row__figure">
          <img
            src="@/assets/icons/self-service.svg"
            alt=""
          >
        </div>
        <div class="card-row__text">
          <h4 style="margin: 0;">
            {{ $t("home.selfService.title") }}
          </h4>
          <p>{{ $t("home.selfService.description") }}</p>
        </div>
      </RouterLink>
      <RouterLink
        v-if="profile.roles.includes('ocr-user')"
        :to="{name: 'OCR'}"
        class="card-row card-row__link"
      >
        <div class="card-row__figure">
          <img
            src="@/assets/icons/ocr.svg"
            alt=""
          >
        </div>
        <div class="card-row__text">
          <h4 style="margin: 0;">
            {{ $t("home.ocr.title") }}
          </h4>
          <p>{{ $t("home.ocr.description") }}</p>
        </div>
      </RouterLink>
      <RouterLink
        v-if="profile.roles.includes('bist-manager-user')"
        :to="{name:'BISTManagerRegister'}"
        class="card-row card-row__link"
      >
        <div class="card-row__figure">
          <img
            src="@/assets/icons/bist-manager.svg"
            alt=""
          >
        </div>
        <div class="card-row__text">
          <h4 style="margin: 0;">
            {{ $t("home.bistmanager.title") }}
          </h4>
          <p>{{ $t("home.bistmanager.description") }}</p>
        </div>
      </RouterLink>
      <RouterLink
        v-if="profile.roles.includes('aplica-user')"
        :to="{name: 'Aplica'}"
        class="card-row card-row__link"
      >
        <div class="card-row__figure">
          <img
            src="@/assets/icons/aplica.svg"
            alt=""
          >
        </div>
        <div class="card-row__text">
          <h4 style="margin: 0;">
            {{ $t("home.aplica.title") }}
          </h4>
          <p>{{ $t("home.aplica.description") }}</p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  mixins: [store]
}
</script>
