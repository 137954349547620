<template>
  <header class="main-header">
    <div class="container container--header">
      <div
        class="main-header__logo"
      >
        <RouterLink
          :to="routeTo"
          :aria-label="$t('topnavbar.logo.link.aria-label')"
          class="logo"
        >
          <svg><g
            fill="none"
            fill-rule="evenodd"
          ><path
            d="M23.4116667 11.8098782h-1.272381v9.5279744h-2.7538095v-9.5279744h-1.2695238V9.8989231h5.2957143v1.9109551zm-6.7819048-4.9444808l-.0011905 17.5114359c0 2.2698077-1.8461904 3.4450321-4.1230952 3.4450321-2.2766667 0-4.1390476-.9918846-4.1390476-2.918968h.002381c0-.7563653.6159523-1.3701859 1.3747618-1.3701859.7588096 0 1.3742857.6138206 1.3742857 1.3701859h.0011905c0 1.3995962.1121429 2.3492629 1.3864286 2.3492629 1.37 0 1.37-.9539359 1.37-2.875327l.0011905-17.5114359c0-2.2679102 1.8454762-3.4440833 4.1228571-3.4440833 2.2766667 0 4.1390476.9906987 4.1390476 2.9187308h-.0021428c0 .7563654-.6154762 1.3697115-1.3754762 1.3697115-.7585714 0-1.3738095-.6133461-1.3738095-1.3697115h-.0016667c0-1.399359-.1114286-2.3490257-1.3859524-2.3490257-1.3704762 0-1.3697619.9522757-1.3697619 2.8743782zM8.3709524 9.8989231h2.7452381l.0040476 11.4320513H8.3680952l.0028572-11.4320513zM0 .1861859v15.450109C0 29.5478205 15.2492857 37 15.2492857 37s15.3064286-7.4521795 15.3064286-21.3637051V.1861859H0z"
            fill="#009DE0"
          /><text
            font-family="Klavika"
            font-size="17"
            font-weight="500"
            fill="#45555F"
          ><tspan
            x="41"
            y="21"
          > {{ $t(`topnavbar.logo.${$route.meta.app}`) }} </tspan></text></g></svg>
        </RouterLink>
      </div>

      <!-- BIST MANAGER TAB NAVIGATION -->
      <nav
        v-if="$route.name.substring(0,4) === 'BIST'"
        class="main-nav menu menu-active"
      >
        <ul
          class="primary-nav tab-navigation__list"
          style="justify-content: right;"
        >
          <li
            class="tab-navigation__list-item"
            :class="{'tab-navigation__list-item--selected': $route.name === 'BISTManagerRegister'}"
          >
            <RouterLink
              :to="{ name:'BISTManagerRegister' }"
              class="tab-navigation__link"
            >
              {{ $t("bist-manager.register") }}
            </RouterLink>
          </li>
          <li
            class="tab-navigation__list-item"
            :class="{'tab-navigation__list-item--selected': $route.name === 'BISTManagerSearch' || $route.name === 'BISTManagerSearchResults'}"
          >
            <RouterLink
              :to="{ name:'BISTManagerSearch' }"
              class="tab-navigation__link"
            >
              {{ $t("bist-manager.search") }}
            </RouterLink>
          </li>
        </ul>
      </nav>

      <!-- MOBILE TOP NAV BAR -->
      <ul
        class="mobile-nav mobile-only"
        hidden
      >
        <!-- <li class="search"> -->
        <!-- <RouterLink
            :to="{ name: 'SearchResultsPage', query: { q: '', domain: 'records' }, params: { openAdvancedSearch: true } }"
            :aria-label="$t('menu.search.aria-label')"
            class="search-trigger"
            @click="closeMobileMenu"
          >
            <span class="icon icon-search">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  class="icon--stroke"
                  stroke="var(--dark)"
                  stroke-width="2"
                >
                  <ellipse
                    cx="8.261"
                    cy="8.741"
                    rx="6.938"
                    ry="6.972"
                    transform="matrix(-1 0 0 1 16.522 0)"
                  />
                  <path
                    stroke-linecap="round"
                    d="M14.016 13.58l3.9 3.56"
                  />
                </g>
              </svg>
            </span>
          </RouterLink> -->
        <!-- </li> -->
        <!-- <li
          v-if="$auth.isLogged()"
          class="notifications"
        >
          <RouterLink
            :to="{ name: 'NotificationsPage' }"
            :aria-label="$t('menu.notifications.aria-label')"
            class="notifications-trigger"
          >
            <span class="icon icon-notifications">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                >
                  <path d="M0 0h24v24H0z" />
                  <g
                    stroke="var(--dark)"
                    class="icon--stroke"
                  >
                    <path
                      stroke-width="1.8"
                      d="M8.4 17.9v.6091846C8.4 20.4907602 10.0120678 22.1 12 22.1c1.9854817 0 3.6-1.6099733 3.6-3.5908154V17.9H8.4z"
                    />
                    <path
                      stroke-width="2"
                      d="M21.0069377 17.800001h.0012592c-.0012534 0-.0023434-.0000456-.0032896-.0001288zm-.1042042 0c-.5411996-.2676993-1.1397531-.6982787-1.7253008-1.3410252-1.4794491-1.623968-2.3440994-4.126175-2.3440994-7.6379228C16.8333333 6.1589214 14.6698444 4 12 4 9.3301556 4 7.1666667 6.1589214 7.1666667 8.821053c0 3.5117478-.8646503 6.0139548-2.3440994 7.6379228-.5855477.6427465-1.1841012 1.0733259-1.7253008 1.3410252h2.523635L12 17.8000009l6.3790985 1e-7h2.5236347z"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </RouterLink>
          <span
            v-if="profile && hasUnreadNotifications"
            :aria-label="$t('menu.notifications.unread.aria-label')"
            class="notifications__count is-visible"
          />
        </li> -->
        <li>
          <button
            :aria-expanded="String(showMobileMenu)"
            class="mobile-nav-trigger"
            aria-controls="main-nav"
            :aria-label="$t('menu.trigger.aria-label')"
            @click.prevent="toggleMobileMenu"
          >
            <span class="icon icon-burger">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  transform="translate(0 4)"
                  class="icon--fill"
                  fill="var(--dark)"
                  fill-rule="evenodd"
                >
                  <g class="icon-burger__top-bar">
                    <rect
                      width="24"
                      height="2"
                      rx="1"
                    />
                  </g>
                  <g class="icon-burger__center-bar">
                    <rect
                      width="24"
                      height="2"
                      rx="1"
                      y="7"
                    />
                  </g>
                  <g class="icon-burger__bottom-bar">
                    <rect
                      width="24"
                      height="2"
                      rx="1"
                      y="14"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </button>
        </li>
      </ul>
      <!-- END MOBILE TOP NAV BAR -->

      <nav
        id="main-nav"
        ref="mobileSideBar"
        class="main-nav menu menu-active"
      >
        <!-- MOBILE SIDE MENU -->
        <mobile-side-menu
          v-if="isMobile"
          v-model="showMobileMenu"
          class="primary-nav"
          :trap="trap"
          @closed="closeMobileMenu()"
        />

        <ul
          v-else
          class="utility-nav"
        >
          <!-- DESKTOP TOP NAV BAR BUTTONS -->
          <li class="search">
            <!-- <RouterLink
              :to="{ name: 'SearchResultsPage', query: { q: '', domain: 'records' }, params: { openAdvancedSearch: true } }"
              class="search-trigger"
              :aria-label="$t('menu.search.aria-label')"
              @click="closeMobileMenu"
            >
              <span class="icon icon-search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                    class="icon--stroke"
                    stroke="var(--gray-400)"
                    stroke-width="2"
                  >
                    <ellipse
                      cx="8.261"
                      cy="8.741"
                      rx="6.938"
                      ry="6.972"
                      transform="matrix(-1 0 0 1 16.522 0)"
                    />
                    <path
                      stroke-linecap="round"
                      d="M14.016 13.58l3.9 3.56"
                    />
                  </g>
                </svg>
              </span>
            </RouterLink> -->
          </li>
          <!-- <li
            v-if="$auth.isLogged()"
            class="notifications"
          >
            <RouterLink
              :to="{ name: 'NotificationsPage' }"
              :aria-label="$t('menu.notifications.aria-label')"
              class="notifications-trigger"
            >
              <span class="icon icon-notifications">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path d="M0 0h24v24H0z" />
                    <g
                      stroke="var(--gray-400)"
                      class="icon--stroke"
                    >
                      <path
                        stroke-width="1.8"
                        d="M8.4 17.9v.6091846C8.4 20.4907602 10.0120678 22.1 12 22.1c1.9854817 0 3.6-1.6099733 3.6-3.5908154V17.9H8.4z"
                      />
                      <path
                        stroke-width="2"
                        d="M21.0069377 17.800001h.0012592c-.0012534 0-.0023434-.0000456-.0032896-.0001288zm-.1042042 0c-.5411996-.2676993-1.1397531-.6982787-1.7253008-1.3410252-1.4794491-1.623968-2.3440994-4.126175-2.3440994-7.6379228C16.8333333 6.1589214 14.6698444 4 12 4 9.3301556 4 7.1666667 6.1589214 7.1666667 8.821053c0 3.5117478-.8646503 6.0139548-2.3440994 7.6379228-.5855477.6427465-1.1841012 1.0733259-1.7253008 1.3410252h2.523635L12 17.8000009l6.3790985 1e-7h2.5236347z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span class="icon-label">
                {{ $t('menu.language.aria-label') }}
              </span>
            </RouterLink>
            <span
              v-if="profile && hasUnreadNotifications"
              :aria-label="$t('menu.notifications.unread.aria-label')"
              class="notifications__count is-visible"
            />
          </li> -->
          <li class="languages">
            <Dropdown
              size="md"
              class="dropdown--with-check"
            >
              <template #dropdown-trigger>
                <button
                  :aria-label="$t('menu.language.aria-label')"
                  class="lang-trigger"
                  aria-haspopup="true"
                >
                  <span class="icon icon-lang">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="16"
                    >
                      <path
                        fill="var(--gray-400)"
                        class="icon--fill"
                        fill-rule="nonzero"
                        d="M9.399 9.399c-1.202-1.231-2.404-2.35-2.95-4.923h4.48V2.573h-4.48V0H4.48v2.573H0v1.903h4.48c0 .67.11.335 0 .67-.655 2.462-1.42 4.14-4.48 5.707l.656 1.902c2.95-1.566 4.48-3.468 5.136-5.706.656 1.678 1.749 3.02 2.951 4.252L9.4 9.399zM15.3 3.133h-2.514L8.306 16h1.967l1.312-3.804h5.136L18.033 16H20L15.3 3.133zm-3.17 7.049l1.967-5.147 1.968 5.147H12.13z"
                      />
                    </svg>
                  </span>
                  <span class="icon-label">
                    {{ $t('menu.language.aria-label') }}
                  </span>
                </button>
              </template>
              <template #dropdown-panel>
                <div>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-menu__item"
                    >
                      <button
                        :class="{'dropdown-menu__link--selected': $i18n.locale === 'en'}"
                        class="dropdown-menu__link"
                        @click.prevent="setLocale('en', $auth)"
                      >
                        <span class="link-text">
                          {{ $t('menu.language.options.en') }}
                        </span>
                      </button>
                    </li>
                    <li
                      class="dropdown-menu__item"
                    >
                      <button
                        :class="{'dropdown-menu__link--selected': $i18n.locale === 'pt'}"
                        class="dropdown-menu__link"
                        @click.prevent="setLocale('pt', $auth)"
                      >
                        <span class="link-text">
                          {{ $t('menu.language.options.pt') }}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </template>
            </Dropdown>
          </li>

          <li
            v-if="$auth.isLogged() && profile"
            class="user"
          >
            <user-dropdown-menu @open="updateProfile" />
          </li>
          <li
            v-if="!$auth.isLogged()"
            class="login"
          >
            <button
              class="btn--login"
              @click.prevent="fetchProfile()"
            >
              {{ $t('menu.login') }}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import Dropdown from '@/components/utils/Dropdown.vue'
import UserDropdownMenu from '@/components/nav-menus/UserDropdownMenu.vue'
import MobileSideMenu from '@/components/nav-menus/MobileSideMenu.vue'
import debounce from 'underscore/modules/debounce'
import store from '@/store'

import { createFocusTrap } from 'focus-trap'

export default {
  components: {
    Dropdown,
    UserDropdownMenu,
    MobileSideMenu
  },
  mixins: [store],
  emits: ['toggle-mobile-menu'],
  data () {
    return {
      mobileMenuBreakpoint: 1200,
      showMobileMenu: false,
      windowWidth: 0,
      isMobile: false,
      hasUnreadNotifications: false,
      trap: null
    }
  },
  computed: {
    logoSrc () {
      if (this.$route.meta.logoSrc) return this.$route.meta.logoSrc
      return '/assets/nav-logo.svg'
    },
    routeTo () {
      if (this.$route.meta.routeTo) return this.$route.meta.routeTo
      return '/'
    }
  },
  watch: {
    windowWidth: {
      immediate: true,
      handler (newWidth) {
        if (newWidth < this.mobileMenuBreakpoint) {
          this.isMobile = true
        } else {
          this.closeMobileMenu()
          this.isMobile = false
        }
      }
    }
  },
  async mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      this.getWindowWidth()
    })
    this.trap = createFocusTrap(this.$refs.mobileSideBar, {
      clickOutsideDeactivates: true,
      onActivate: () => {
        this.showMobileMenu = true
        document.body.classList.add('scrollLock')
        this.$emit('toggle-mobile-menu', this.showMobileMenu)
      },
      onDeactivate: () => (
        setTimeout(() => {
          this.showMobileMenu = false
          document.body.classList.remove('scrollLock')
          this.$emit('toggle-mobile-menu', this.showMobileMenu)
        }, 100)
      )
    })

    const vhFix = debounce(() => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })

    window.addEventListener('resize', vhFix)
    vhFix()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    closeMobileMenu () {
      this.trap.deactivate()
    },
    openMobileMenu () {
      this.trap.updateContainerElements(this.$refs.mobileSideBar)
      this.trap.activate()
    },
    setLocale (l) {
      this.$i18n.locale = l
    },
    async toggleMobileMenu () {
      if (this.showMobileMenu) {
        this.closeMobileMenu()
      } else {
        this.openMobileMenu()
        if (this.$auth.isLogged()) {
          await this.updateProfile()
        }
      }
    },
    async updateProfile () {
      await this.fetchProfile()
    }
  }
}
</script>
<style lang="scss">
@use "sass:color";

// import variables
@import "@/assets/scss/variables";
@import "@/assets/scss/buttons";
@import "@/assets/scss/layouts/header";

// ================== Main Header Styles
.main-header,
.container--header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.main-header {
  background: color.change(#fff, $alpha: 0.97);
  width: 100%;
  height: $header-height;
  box-shadow: 0 0.0625rem 0 0 color.change($dark, $alpha: 0.1);
  position: fixed;
  z-index: 2;

  // padding: 0.25rem 0;
}

.main-header__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 0;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  max-height: 100%;
}

.logo img,
.logo svg {
  width: auto;
  height: 100%;
  object-fit: contain;
}

.main-header__logo .logo svg.ul {
  width: 100%;
  height: auto;
}

.mobile-nav {
  display: flex;
  align-items: center;
}

.mobile-nav li ~ li {
  margin-left: 1.5rem;
}

// ================== Global Menu Styles
.menu .moves-out {
  transform: translateX(-100%);
}

.menu > ul ul {
  position: absolute;
  top: 0;
  left: 100%;
}

.menu .has-children > a::before,
.menu .go-back > a::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.menu .has-children > a::after,
.menu .go-back > a::after {
  position: absolute;
  content: "";
  top: 50%;
  height: 0.625rem;
  width: 0.625rem;
  border: 0.125rem solid $slate;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg) translateY(-50%);
}

.menu .has-children > a::after {
  right: 1rem;
}

.menu .go-back > a {
  padding-left: 2.5rem;
}

.menu .go-back > a::after {
  left: 1rem;
  transform: rotate(-135deg) translateY(50%);
}

// a editar
.menu .is-hidden {
  opacity: 0%;
  visibility: hidden;
  pointer-events: none;
}

// IF CSS is disabled, visibility of .mobile-only elements is turned off with attribute "hidden" to simplify document outline,
// but if CSS is enabled they are needed
.mobile-only[hidden] {
  display: flex;

  @media screen and (width >= 75rem) {
    display: none;
  }
}

// ================== end Global Menu Styles

// ================== Main Nav on mobile
.main-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -#{$menu-mobile-width};
  width: $menu-mobile-width;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  background-color: $dark-600;
  overflow: hidden auto;
}

.main-nav ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition-property: all;

  @include fast-transition;
}

.main-nav a {
  position: relative;
  color: #fff;
  display: flex;
  padding: 0 16px;
  width: 100%;
  height: $header-height;
  line-height: $header-height;
  border-bottom: 0.0625rem solid $dark;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// mobile active state
.primary-nav li .active {
  color: $blue;
  font-weight: 600;
}

// ================== end of Main Nav on mobile

// ================== Main Nav on desktop
@media screen and (width >= 75rem) {
  // ================== Global Menu Styles
  .menu > ul ul {
    position: static;
  }

  .menu a {
    color: $dark;
    display: block;
    font-size: 1rem;
    transition-property: color;

    @include md-transition;

    &:hover,
    &:focus {
      color: $blue-600;
    }

    &:active {
      color: $blue-700;
    }
  }

  // ================== end Global Menu Styles

  .main-nav {
    position: static;
    width: calc(100% - 23rem);
    height: auto;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-left: auto;
    transition-property: none;
    overflow: visible;
  }

  // .main-nav ul,
  .main-nav > ul {
    flex-direction: row;
    width: auto;
  }

  .main-nav > ul > li {
    display: flex;
  }

  .main-nav > ul > li > a {
    display: flex;
    align-items: center;
  }

  .main-nav a {
    height: auto;
    line-height: normal;
    white-space: nowrap;
    white-space: normal;
    padding: 0;
    border-bottom: none;
  }

  .main-nav .has-children > a::after,
  .main-nav .go-back > a::after {
    content: initial;
  }

  .main-nav .has-children.has-focus > a,
  .main-nav .has-children:focus-within > a {
    color: $blue-600;
  }

  .main-nav .secondary-nav {
    background-color: $light-blue;
    padding: 2.5rem calc((100vw - 1110px) / 2);
    position: absolute;
    left: 0;
    right: 0;
    top: $header-height;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    transition-property: opacity, visibility;

    @include md-transition;

    transition-delay: 0.18s;
  }

  .primary-nav > li ~ li {
    margin-left: 2rem;
  }

  .primary-nav > li.has-children:focus-within .is-hidden {
    opacity: 100%;
    visibility: visible;
    pointer-events: all;
  }

  // focus-within not widely supported yet
  .primary-nav > li.has-children:focus-within > a {
    color: $blue-600;
    box-shadow: inset 0 -0.125rem 0 $blue-600;
  }

  // alternative to focus-within -> add class .has-focus via js
  .primary-nav > li.has-children:hover .is-hidden,
  .primary-nav > li.has-children.has-focus .is-hidden {
    opacity: 100%;
    visibility: visible;
    pointer-events: all;
  }

  .primary-nav > li.has-children > a {
    position: relative;
    box-shadow: inset 0 -0.125rem 0 transparent;
    transition-property: color, box-shadow;

    @include md-transition;

    &:hover,
    &:focus {
      box-shadow: inset 0 -0.125rem 0 $blue-600;
    }

    &:active {
      box-shadow: inset 0 -0.125rem 0 $blue-700;
    }
  }

  .primary-nav > li.has-children.has-focus > a {
    color: $blue-600;
    box-shadow: inset 0 -0.125rem 0 $blue-600;

    &:active {
      color: $blue-700;
      box-shadow: inset 0 -0.125rem 0 $blue-700;
    }
  }

  p.has-image {
    font-weight: 400;
    margin-top: 1rem;
  }

  p.has-image img {
    margin: 0.5rem 16px 0 0;
  }

  .secondary-nav ul {
    flex-direction: column;
  }

  .secondary-nav .intro-message {
    display: block;
    opacity: 65%;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    margin-top: 1rem;
    transition-property: opacity;

    @include md-transition;
  }

  .secondary-nav > li {
    flex-basis: 23%;
    margin-right: 2%;
    overflow: hidden;
  }

  .secondary-nav > li > a {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .secondary-nav > li ul li {
    margin-top: 0.625rem;
  }

  .secondary-nav .intro a:hover p,
  .secondary-nav .intro a:focus p,
  .secondary-nav .intro a:hover span,
  .secondary-nav .intro a:focus span {
    opacity: 100%;
  }

  .utility-nav {
    align-items: center;
    margin-left: auto;
  }

  .utility-nav > li ~ li {
    margin-left: 1rem;
  }

  .utility-nav > li > a {
    font-size: 0.875rem;
  }
}

// ================== Main Header icons
.main-header .utility-nav .icon {
  display: none;

  @media screen and (width >= 75rem) {
    display: flex;

    & + .icon-label {
      display: none;
    }
  }
}

.notifications {
  position: relative;
}

.notifications__count {
  position: absolute;
  top: 3px;
  right: 4px;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $blue-600;

  // to animate (?)
  opacity: 0%;
  transform: scale(0.1);
  transition-property: transform;

  @include md-transition;
}

.notifications__count.is-visible {
  opacity: 100%;
  transform: scale(1);
}

.search-trigger,
.notifications-trigger {
  &:hover,
  &:focus {
    .icon--stroke {
      stroke: $blue-600;
    }
  }

  &:active {
    .icon--stroke {
      stroke: $blue-700;
    }
  }
}

.apps-trigger,
.lang-trigger,
.mobile-nav-trigger {
  &:hover,
  &:focus {
    .icon--fill {
      fill: $blue-600;
    }
  }

  &:active {
    .icon--fill {
      fill: $blue-700;
    }
  }
}

.icon-burger {
  .icon-burger__top-bar,
  .icon-burger__center-bar,
  .icon-burger__bottom-bar {
    transition-property: transform, transform-origin, opacity;

    @include md-transition;
  }

  .nav-is-visible & {
    .icon-burger__top-bar {
      transform-origin: top left;
      transform: rotate(45deg) translate(3px, -4px);
    }

    .icon-burger__center-bar {
      opacity: 0%;
    }

    .icon-burger__bottom-bar {
      transform-origin: bottom left;
      transform: rotate(-45deg) translate(8px, 7px);
    }
  }
}

.icon-lang,
.icon-apps,
.icon-burger {
  .icon--fill {
    transition-property: fill;

    @include md-transition;
  }
}

.icon-search,
.icon-notifications {
  .icon--stroke {
    transition-property: stroke;

    @include md-transition;
  }
}

.main-header .icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Avatars

// user avatar on main-header
.user-container {
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// user avatar on main-header
.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid color.change(black, $alpha:  0.12);
  transition-property: border;

  .user-trigger.has-focus &,
  .user-trigger:hover &,
  .user-trigger:focus & {
    border: 2px solid color.change($blue-600, $alpha: 1);
  }

  .user-trigger:active & {
    border: 2px solid color.change($blue-700, $alpha: 1);
  }
}
</style>
