<template>
  <ul
    ref="menu"
    class="mobile-side-menu"
  >
    <template v-if="$auth.isLogged() && profile">
      <li
        hidden
        class="mobile-only mobile-side-menu__user"
      >
        <div class="link-icon">
          <UserAvatar
            :src="`https://fenix.tecnico.ulisboa.pt/user/photo/${profile.username}?s=80`"
            :name="profile.name"
            class="user-avatar"
          />
        </div>
        <div class="mobile-side-menu__username">
          <div class="link-text">
            {{ profile.name }}
          </div>
          <div
            v-if="profile.username"
            class="link-text link-text--username"
          >
            {{ profile.username }}
          </div>
        </div>
      </li>
    </template>
    <template v-if="$route.meta.app === 'bistmanager'">
      <li
        hidden
        class="mobile-only"
      >
        <RouterLink
          :to="{ name:'BISTManagerRegister' }"
          active-class="active"
          @click.prevent="closeMenu()"
        >
          <span class="link-text">
            {{ $t("bist-manager.register") }}
          </span>
        </RouterLink>
      </li>
      <li>
        <RouterLink
          :to="{ name:'BISTManagerSearch' }"
          active-class="active"
          @click.prevent="closeMenu()"
        >
          <span class="link-text">
            {{ $t("bist-manager.search") }}
          </span>
        </RouterLink>
      </li>
    </template>
    <template v-if="profile.roles.includes('admin')">
      <li
        hidden
        class="mobile-only"
      >
        <RouterLink
          :to="{ name: 'AdministrateUsersPage', }"
          active-class="active"
          @click.prevent="closeMenu()"
        >
          <span class="link-icon">
            <svg
              width="26"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
              >
                <path d="M1 0h24v24H1z" />
                <g
                  transform="translate(1 2)"
                  stroke="var(--gray-400)"
                >
                  <path
                    d="M13.78 13.026c1.041-1.37 2.747-2.263 4.675-2.263 3.172 0 5.744 2.419 5.744 5.402h-7.926 0"
                    stroke-width="1.3"
                  />
                  <ellipse
                    stroke-width="1.2"
                    cx="18.455"
                    cy="6.001"
                    rx="3.191"
                    ry="3.001"
                  />
                  <path
                    d="M16.881 19.094c0-4.31-3.74-7.803-8.356-7.803-4.614 0-8.355 3.493-8.355 7.803H16.88z"
                    stroke-width="1.5"
                  />
                  <ellipse
                    stroke-width="1.5"
                    cx="8.525"
                    cy="4.335"
                    rx="4.642"
                    ry="4.335"
                  />
                </g>
              </g>
            </svg>
          </span>
          <span class="link-text">
            {{ $t("menu.manage-users") }}
          </span>
        </RouterLink>
      </li>
    </template>
    <template v-if="$auth.isLogged()">
      <li
        hidden
        class="mobile-only"
      >
        <button @click.stop="$auth.logout()">
          <span class="link-text">
            {{ $t('menu.logout') }}
          </span>
        </button>
      </li>
    </template>
    <li
      hidden
      class="has-children mobile-only language-menu"
      :class="{ 'menu-entry--snap-to-bottom': $auth.isLogged() }"
    >
      <button
        aria-controls="language-menu"
        @click.prevent="openSubMenu"
      >
        <span class="link-text">
          {{ $t('menu.language.label') }}
        </span>
      </button>
      <ul
        id="language-menu"
        class="secondary-nav is-hidden"
      >
        <li class="go-back mobile-only">
          <button
            aria-controls="language-menu"
            @click.prevent="closeSubMenu"
          >
            <span class="link-text">
              {{ $t('menu.back') }}
            </span>
          </button>
        </li>
        <li>
          <button
            v-if="$i18n.locale === 'en'"
            @click.prevent="closeMenu() + setLocale('pt', $auth)"
          >
            <span class="link-text">
              {{ $t('menu.language.options.pt') }}
            </span>
          </button>
        </li>
        <li>
          <button
            v-if="$i18n.locale === 'pt'"
            @click.prevent="closeMenu() + setLocale('en', $auth)"
          >
            <span class="link-text">
              {{ $t('menu.language.options.en') }}
            </span>
          </button>
        </li>
      </ul>
    </li>
    <li
      v-if="!$auth.isLogged()"
      hidden
      class="login mobile-only menu-entry--snap-to-bottom"
    >
      <button
        class="btn--login"
        @click.prevent="fetchProfile()"
      >
        {{ $t('menu.login') }}
      </button>
    </li>
  </ul>
</template>
<script>
import UserAvatar from '@/components/utils/UserAvatar.vue'
import store from '@/store'
export default {
  components: {
    UserAvatar
  },
  mixins: [store],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    trap: {
      type: Object,
      default: null
    }
  },
  emits: ['closed'],
  watch: {
    modelValue (isOpen) {
      if (!isOpen) {
        this.closeMenu()
      }
    }
  },
  methods: {
    async openSubMenu (event) {
      let selectedElement = event.target
      while (selectedElement.tagName !== 'LI') {
        selectedElement = selectedElement.parentNode
      }
      const subMenuElement = selectedElement.querySelector('ul')
      const parentNav = selectedElement.parentNode
      if (subMenuElement.classList.contains('is-hidden')) {
        selectedElement.classList.add('selected')
        subMenuElement.classList.remove('is-hidden')
        parentNav.classList.add('moves-out')
      } else {
        selectedElement.classList.remove('selected')
        parentNav.classList.add('moves-out')
      }
      await this.$nextTick()
      setTimeout(() => this.trap.updateContainerElements(subMenuElement), 100)
    },
    closeSubMenu (event) {
      let selectedElement = event.target
      while (selectedElement.tagName !== 'LI') {
        selectedElement = selectedElement.parentNode
      }
      const currentNav = selectedElement.parentNode
      const previousNav = currentNav.parentNode.parentNode
      currentNav.classList.add('is-hidden')
      currentNav.parentNode.classList.remove('selected')
      previousNav.classList.remove('moves-out')
      this.trap.updateContainerElements(previousNav)
    },
    closeMenu () {
      const mobileMenu = this.$refs.menu
      if (!mobileMenu) return
      const navigations = mobileMenu.querySelectorAll('.has-children ul')
      const selectedNavigations = mobileMenu.querySelectorAll('.has-children a')
      const elementsMovedOut = [mobileMenu, ...mobileMenu.querySelectorAll('.moves-out')]

      for (const navigation of navigations) {
        navigation.classList.add('is-hidden')
      }
      for (const selectedNavigation of selectedNavigations) {
        selectedNavigation.classList.remove('selected')
      }
      for (const elementMovedOut of elementsMovedOut) {
        elementMovedOut.classList.remove('moves-out')
      }
      this.$emit('closed')
    }
  }
}
// TODO: move styles from TopNavBar to this component?
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.mobile-side-menu {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.menu-entry--snap-to-bottom {
  margin-top: auto;

  &.language-menu {
    border-top: 0.0625rem solid $dark;
  }
}

.mobile-side-menu__user {
  position: relative;
  color: #fff;
  display: flex;
  padding: 1rem;
  width: 100%;
  min-height: calc(2rem + #{$header-height});
  border-bottom: 0.0625rem solid $dark;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .mobile-side-menu__username {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .link-icon {
    margin-right: 1rem;
  }

  .link-text--username {
    color: $gray-400;
  }

  .user-avatar {
    border: none;
  }
}
</style>
