<template>
  <div class="empty-state">
    <div class="empty-state__illustration">
      <svg
        width="71"
        height="86"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="#666666"
          stroke-width="2.88"
          fill="none"
          fill-rule="evenodd"
        >
          <path d="M5.76 2.68h60.48a2.88 2.88 0 012.88 2.88v74.88a2.88 2.88 0 01-2.88 2.88H5.76a2.88 2.88 0 01-2.88-2.88V5.56a2.88 2.88 0 012.88-2.88zM14.4 50.2h43.2M14.4 43h43.2M14.4 35.8h43.2M14.4 28.6h43.2M14.4 21.4H36" /><path
            d="M16.56 71.8c5.28-6.24 8.16-9.36 8.64-9.36.72 0 1.44 1.44.72 3.6s-1.44 3.6 0 3.6 3.6-1.8 4.32-2.16c.72-.36 1.44.72.72 1.44s-.72.72 0 .72 2.88-2.16 4.32-2.88c1.44-.72 2.16 0 2.88.72.72.72 2.16 1.44 4.32 1.44 1.44 0 3.12-.24 5.04-.72"
            stroke-linecap="round"
          />
        </g>
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
  </div>
</template>
