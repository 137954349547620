<template>
  <div class="empty-state">
    <div class="empty-state__illustration">
      <svg
        width="117"
        height="76"
        viewBox="0 0 117 76"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          stroke="#666666"
          stroke-width="2"
          fill="none"
          fill-rule="evenodd"
        >
          <path
            d="M1.5 74.179688h113.568"
            stroke-linecap="round"
          />
          <path
            d="M90.38 31.667688h19.632v41.264H90.38zM6.556 31.667688h19.632v41.264H6.556zM3.852 24.659688h25.04v6.112H3.852zM87.676 24.659688h25.04v6.112h-25.04zM25.484 17.651688h65.6v6.112h-65.6z"
          />
          <path
            stroke-linecap="round"
            d="M52.876 1.531688h13.52l-3.38 4.056 3.38 4.056h-13.52z"
          />
          <path
            d="M52.876 17.755688v-17.576M55.932 34.827688h4.704v14.168h-4.704zM45.116 34.827688h4.704v14.168h-4.704zM34.3 34.827688h4.704v14.168H34.3zM66.748 34.827688h4.704v14.168h-4.704zM77.564 34.827688h4.704v14.168h-4.704zM97.844 37.531688h4.704v14.168h-4.704zM97.844 59.163688h4.704v11.464h-4.704zM14.02 37.531688h4.704v14.168H14.02zM14.02 59.163688h4.704v11.464H14.02zM55.932 56.459688h4.704v14.168h-4.704zM45.116 56.459688h4.704v14.168h-4.704zM34.3 56.459688h4.704v14.168H34.3zM66.748 56.459688h4.704v14.168h-4.704zM77.564 56.459688h4.704v14.168h-4.704z"
          />
        </g>
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
  </div>
</template>
