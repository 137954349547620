<template>
  <section>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :page-data="studentData"
      :always-show-search="true"
      :show-counter="false"
    >
      <template #empty-state>
        <AplicaStudentEmptyState>
          <template #description>
            {{ $t("aplica-results.empty-state.studentData") }}
          </template>
        </AplicaStudentEmptyState>
      </template> <template #list-skeleton>
        <AplicaStudentData
          :count="1"
        />
      </template>
      <template #list>
        <div
          class="card"
        >
          <div class="card-row">
            <h3 style="margin: 0;">
              {{ $t('aplica-results.labels.studentData') }}
            </h3>
          </div>
          <div
            v-for="degree in studentData.items.degrees.degree"
            :key="degree.degreeId.degree"
            class="card-row"
          >
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.studentNumber') }}</span>{{ studentData.items.studentId.studentNumber ? studentData.items.studentId.studentNumber : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.degreeCode') }}</span>{{ degree.degreeId.degree ? degree.degreeId.degree : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.field') }}</span>{{ degree.degreeId.field ? degree.degreeId.field : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.orientation') }}</span>{{ degree.degreeId.orientation ? degree.degreeId.orientation : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.pole') }}</span>{{ degree.degreeId.pole ? degree.degreeId.pole : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.admission') }}</span>{{ degree.degreeId.admission ? degree.degreeId.admission : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.average') }}</span>{{ degree.degreeId.average ? degree.degreeId.average : "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.degreeEndingDate') }}</span>{{ degree.degreeId.degreeEnd ? degree.degreeId.degreeEnd : "N/A" }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </SearchPaginatedList>
  </section>
</template>

<script>
import AplicaStudentEmptyState from '@/components/empty-states/Search.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import AplicaStudentData from '@/components/loading-skeletons/AplicaStudentData.vue'
export default {
  components: { AplicaStudentEmptyState, SearchPaginatedList, AplicaStudentData },
  props: {
    studentData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false
    }
  },
  mounted () {
    this.isInitialLoading = false
  },
  methods: {
    formatSex (sexChar) {
      if (sexChar === 'M') return this.$t('aplica-results.sex.masculine')
      if (sexChar === 'F') return this.$t('aplica-results.sex.feminine')
    },
    formatDate ({ dateString }) {
      if (dateString === undefined) return

      const year = dateString.substring(0, 4)

      let month = Number(dateString.substring(4, 6))
      month = this.$t(`months.${month}`)

      const day = dateString.substring(6, 8)

      return `${day} ${month} ${year}`
    }
  }
}
</script>
