<template>
  <section style="color: black;">
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :page-data="payments"
      :always-show-search="true"
      :show-counter="false"
    >
      <template #empty-state>
        <AplicaPaymentsEmptyState>
          <template #description>
            {{ $t("aplica-results.empty-state.payments") }}
          </template>
        </AplicaPaymentsEmptyState>
      </template>
      <template #list-skeleton>
        <AplicaPayments
          :count="10"
        />
      </template>
      <template #list>
        <div
          v-for="(payment, index) in payments.items"
          :key="index"
          class="card"
        >
          <div class="card-row">
            <h3 style="margin: 0;">
              {{ payment.product ?? "N/A" }}
            </h3>
          </div>
          <div class="card-row">
            <ul>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.academicYear') }}</span>{{ payment.academicYear ?? "N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.escudos') }}</span>{{ payment.escudos ?? "00000.00" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.euros') }}</span>{{ payment.euros ?? "00000.00" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.credit') }}</span>{{ payment.credit ?? "00000.00" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.restitution') }}</span>{{ payment.restitution ?? "00000.00" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.releaseDate') }}</span>{{ payment.entry ??"N/A" }}
              </li>
              <li>
                <span class="search-results__label">{{ $t('aplica-results.labels.paymentDate') }}</span>{{ payment.paymentDate ? formatDate({dateString: payment.paymentDate}) : "N/A" }}
              </li>
            </ul>
          </div>
        </div>
      </template>
    </searchpaginatedlist>
  </section>
</template>

<script>
import AplicaPaymentsEmptyState from '@/components/empty-states/AplicaPayments.vue'
import AplicaPayments from '@/components/loading-skeletons/AplicaPayments.vue'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'

export default {
  components: { AplicaPaymentsEmptyState, SearchPaginatedList, AplicaPayments },
  props: {
    payments: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false
    }
  },
  computed: {
    sortedPayments () {
      const sortedPayments = this.payments
      sortedPayments.sort(function (a, b) {
        return b.paymentDate - a.paymentDate
      })
      return sortedPayments
    }
  },
  mounted () {
    this.isInitialLoading = false
  },
  methods: {
    formatDate ({ dateString }) {
      if (dateString === undefined) return
      if (typeof dateString === 'number') dateString = dateString.toString()

      const year = dateString.substring(0, 4)

      let month = Number(dateString.substring(4, 6))
      month = this.$t(`months.${month}`)

      const day = dateString.substring(6, 8)

      return `${day} ${month} ${year}`
    }
  }
}
</script>
