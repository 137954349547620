<template>
  <Dropdown
    ref="dropdown"
    size="lg"
    @hide="closeMenu"
    @open="$emit('open')"
    @trap="setFocusTrap"
  >
    <template #dropdown-trigger>
      <button
        :aria-label="$t('menu.account.aria-label')"
        class="user-trigger"
        aria-haspopup="true"
      >
        <UserAvatar
          :name="profile.name"
          :src="`https://fenix.tecnico.ulisboa.pt/user/photo/${profile.username}?s=80`"
          class="figure--36"
        />
      </button>
    </template>
    <template #dropdown-panel>
      <div
        ref="dropdownPanel"
        class="user-dropdown-menu__panel"
      >
        <ul
          ref="menu"
          class="dropdown-menu"
        >
          <li class="dropdown-user__details">
            <UserAvatar
              :name="profile.name"
              :src="`https://fenix.tecnico.ulisboa.pt/user/photo/${profile.username}?s=80`"
              class="figure--48"
            />
            <div class="dropdown-user__text">
              <div class="name">
                {{ profile.name }}
              </div>
              <div
                v-if="profile.username"
                class="username"
              >
                {{ profile.username }}
              </div>
            </div>
          </li>
          <li class="dropdown-menu__item">
            <RouterLink
              v-if="profile.roles.includes('admin')"
              :to="{ name: 'AdministrateUsersPage', }"
              class="dropdown-menu__link"
            >
              <span class="link-icon">
                <svg
                  width="26"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path d="M1 0h24v24H1z" />
                    <g
                      transform="translate(1 2)"
                      stroke="var(--gray-400)"
                    >
                      <path
                        d="M13.78 13.026c1.041-1.37 2.747-2.263 4.675-2.263 3.172 0 5.744 2.419 5.744 5.402h-7.926 0"
                        stroke-width="1.3"
                      />
                      <ellipse
                        stroke-width="1.2"
                        cx="18.455"
                        cy="6.001"
                        rx="3.191"
                        ry="3.001"
                      />
                      <path
                        d="M16.881 19.094c0-4.31-3.74-7.803-8.356-7.803-4.614 0-8.355 3.493-8.355 7.803H16.88z"
                        stroke-width="1.5"
                      />
                      <ellipse
                        stroke-width="1.5"
                        cx="8.525"
                        cy="4.335"
                        rx="4.642"
                        ry="4.335"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span class="link-text">
                {{ $t("menu.manage-users") }}
              </span>
            </RouterLink>
          </li>
          <li class="dropdown-menu__item">
            <button
              v-if="$auth.isLogged()"
              class="dropdown-menu__link"
              @click.stop="$auth.logout()"
            >
              <span class="link-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path d="M-6-3h24v24H-6z" />
                    <g
                      stroke="var(--gray-400)"
                      stroke-linecap="round"
                    >
                      <path
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9.076923 1.5h-8V18h7.5750767"
                      />
                      <path
                        stroke-width="1.8"
                        d="M12 6l4 4.0122369L12.0256659 14M6 10h9"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span class="link-text">
                {{ $t('menu.logout') }}
              </span>
            </button>
          </li>
        </ul>
      </div>
    </template>
  </Dropdown>
</template>
<script>
import Dropdown from '@/components/utils/Dropdown.vue'
import UserAvatar from '@/components/utils/UserAvatar.vue'
import store from '@/store'

export default {
  components: {
    Dropdown,
    UserAvatar
  },
  mixins: [store],
  emits: ['open'],
  data () {
    return {
      trap: null
    }
  },
  methods: {
    setFocusTrap (trap) {
      this.trap = trap
    },
    async openSubMenu (event) {
      const selectedElement = event.target.parentNode
      const subMenuElement = selectedElement.querySelector('ul')
      const parentNav = selectedElement.parentNode
      // adjust height of nav to selected content
      const dropdownPanel = this.$refs.dropdownPanel.parentNode
      dropdownPanel.style.height = `${subMenuElement.offsetHeight}px`

      if (subMenuElement.classList.contains('is-hidden')) {
        selectedElement.classList.add('selected')
        subMenuElement.classList.remove('is-hidden')
        parentNav.classList.add('moves-out')
      } else {
        selectedElement.classList.remove('selected')
        parentNav.classList.add('moves-out')
      }
      await this.$nextTick()
      setTimeout(() => this.trap.updateContainerElements(subMenuElement), 100)
    },
    closeSubMenu (event) {
      const selectedElement = event.target.parentNode
      const currentNav = selectedElement.parentNode
      const previousNav = currentNav.parentNode.parentNode

      // adjust height of nav to selected content
      const dropdownPanel = this.$refs.dropdownPanel.parentNode
      dropdownPanel.style.height = `${previousNav.offsetHeight}px`

      currentNav.classList.add('is-hidden')
      currentNav.parentNode.classList.remove('selected')
      previousNav.classList.remove('moves-out')
      this.trap.updateContainerElements(previousNav)
    },
    closeMenu () {
      const menu = this.$refs.menu
      if (!menu) return
      const navigations = menu.querySelectorAll('.has-children ul')
      const selectedNavigations = menu.querySelectorAll('.has-children button')
      const elementsMovedOut = menu.querySelectorAll('.moves-out')

      for (const navigation of navigations) {
        navigation.classList.add('is-hidden')
      }
      for (const selectedNavigation of selectedNavigations) {
        selectedNavigation.classList.remove('selected')
      }
      for (const elementMovedOut of elementsMovedOut) {
        elementMovedOut.classList.remove('moves-out')
      }

      setTimeout(() => {
      // reset height of nav to automatic detection
        const dropdownPanel = this.$refs.dropdownPanel.parentNode
        dropdownPanel.setAttribute('style', '')
      }, 100)

      menu.classList.remove('moves-out')
    }
  }
}
// TODO: move styles from TopNavBar to this component?
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

.user-dropdown-menu__panel {
  overflow: hidden;
  height: 100%;
}

// FIXME: remove !important when menu styles are moved from TopNavBar to menu components
.dropdown-menu__secondary-nav {
  position: absolute !important;
  top: 0 !important;
  left: 100% !important;
  width: 100% !important;
}

.dropdown-menu__item .link-text {
  // disables clicks on the spans, bc we want clicks to fire on the buttons
  pointer-events: none;
}

.dropdown-menu__secondary-nav > .dropdown-menu__item a {
  padding-left: 2.5rem;
}

.dropdown-menu .has-children > button::after,
.dropdown-menu .go-back > button::after {
  position: absolute;
  content: "";
  top: 50%;
  height: 0.625rem;
  width: 0.625rem;
  border: 0.125rem solid $gray-400;
}

.dropdown-menu .has-children > button::after {
  border-left: 0;
  border-bottom: 0;
  right: 2rem;
  transform: rotate(45deg) translateY(-50%);
}

.dropdown-menu .go-back > button::after {
  border-right: 0;
  border-top: 0;
  left: 1.25rem;
  transform: rotate(45deg) translateY(-50%);
}
</style>
