import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './style.css'
import App from './App.vue'
import router from '@/router/index'
import i18n from '@/i18n/index'
import PorgPlugin from 'vue-porg'
import client from '@/api/client'
const pinia = createPinia()

createApp(App).use(PorgPlugin, {
  router,
  axios: client,
  default2FAChallenge: 'U2FChallenge'
}).use(pinia).use(router).use(i18n).mount('#app')
