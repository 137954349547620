<template>
  <div
    v-for="x in parseInt(count)"
    :key="x"
    class="card card-list"
  >
    <div class="card-row">
      <h3
        style="margin-bottom: 0;"
        class="loading-text"
      >
        Loading the monography title
      </h3>
    </div>
    <div class="card-row">
      <ul>
        <li><span class="loading-text">Loading the monography id</span></li>
        <li><span class="loading-text">Loading the monography authors</span></li>
      </ul>
    </div>
    <div class="card-row--with-columns">
      <div class="card-row__column">
        <ul>
          <li><span class="loading-text">Loading acquisition form</span></li>
          <li><span class="loading-text">Loading type-of-document</span></li>
          <li><span class="loading-text">Loading library</span></li>
          <li><span class="loading-text">Loading the monography userId</span></li>
          <li><span class="loading-text">Loading the date</span></li>
        </ul>
      </div>
      <div class="card-row__column">
        <ul>
          <li><span class="loading-text">Loading acquisition form</span></li>
          <li><span class="loading-text">Loading type-of-document</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: prop => Number.isInteger(prop) & prop > 0
    }
  }
}
</script>
