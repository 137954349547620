<template>
  <div class="card-row card-row--sm">
    <div class="card-row__text doc-upload-list__item">
      <div class="file-header">
        <div class="file-header__description">
          <div>
            <span class="file-header__name">
              {{ file.name }}
            </span>
            ({{ prettyBytes(file.size) }})
          </div>
        </div>
        <div class="file-header__meta">
          <button
            class="i-removeOrCloseIcon"
            :aria-label="$t('more.options')"
            @click.prevent="$emit('delete', file)"
          />
        </div>
      </div>
      <div
        v-if="file.state === 'error' && file.error.key"
        class="file-error"
      >
        <p
          style="margin-left: 0.5rem;"
          class="u-text-danger"
        >
          <template v-if="file.error.key === 'invalid-file'">
            {{ $t('fields.file.fileRow.error.invalidFile') }}
          </template>
          <template v-else>
            {{ $t('fields.file.fileRow.error.unknown') }}
          </template>
        </p>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    file: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['validate', 'delete'],
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    prettyBytes () {
      return (num) => {
        if (typeof num !== 'number' || isNaN(num)) {
          throw new TypeError('Expected a number')
        }

        const neg = num < 0
        const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        if (neg) {
          num = -num
        }

        if (num < 1) {
          return (neg ? '-' : '') + num + ' B'
        }

        const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
        num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
        const unit = units[exponent]

        return (neg ? '-' : '') + num + ' ' + unit
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";

// .doc-upload-list__item {
// display: flex;
// flex-flow: column;
// flex-grow: 1;
// }

.file-header {
  display: flex;
  flex-flow: column nowrap;

  @media screen and (width >= 60rem) {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.file-header__description {
  display: flex;
  flex-flow: column nowrap;
}

.file-header__name {
  font-weight: 600;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.file-header__meta {
  position: absolute;
  align-self: flex-end;
  margin-top: 0.5rem;
  @media screen and (width >= 60rem) {
    margin-top: 0;
    position: static;
    margin-left: auto;
    align-self: center;
  }
}
</style>
