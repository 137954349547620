<template>
  <div
    class="f-field f-field--select"
    :class="{'f-field--required' : isRequired}"
  >
    <select
      class="f-field__input"
      :style="{'opacity': modelValue == '' ? 0.5 : 1 }"
      :class="{ 'f-field__input--is-filled': modelValue !== '' }"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
        value=""
      >
        {{ placeholder ? placeholder : $t(`fields.dropdown.placeholder`) }}
      </option>
      <option
        v-for="opt in options"
        :key="fieldName + opt"
        :value="opt.value"
      >
        {{ opt.label }}
      </option>
    </select>
    <label
      v-if="showLabel"
      :for="fieldName"
      class="f-field__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
// import Tooltip from '@/components/utils/Tooltip.vue'

export default {
  // components: {
  //   Tooltip
  // },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    return { value: '' }
  }
}
</script>
