<template>
  <div
    class="empty-state"
  >
    <div class="empty-state__illustration">
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="75"
          cy="75"
          r="39"
          stroke="#717782"
          stroke-width="3"
        />
        <path
          d="M75 90C83.25 90 87.75 97.5 87.75 97.5"
          stroke="#717782"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M75.75 90C67.5 90 63 97.5 63 97.5"
          stroke="#717782"
          stroke-width="3"
          stroke-linecap="round"
        />
        <circle
          cx="58.5"
          cy="70.5"
          r="4.5"
          stroke="#717782"
          stroke-width="3"
        />
        <circle
          cx="93"
          cy="70.5"
          r="4.5"
          stroke="#717782"
          stroke-width="3"
        />
      </svg>
    </div>
    <p class="h4 empty-state__title">
      <slot name="title" />
    </p>
    <p class="empty-state__description">
      <slot name="description" />
    </p>
    <button
      v-if="showButton"
      class="empty-state__button"
      @click="$router.push({ name: searchRouteName })
      "
    >
      <slot name="button" />
    </button>
  </div>
</template>
<script>
export default {
  props: {
    showButton: {
      type: Boolean,
      default: false
    },
    searchRouteName: {
      type: String,
      default: null
    }
  }
}
</script>
