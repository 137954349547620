import client from '@/api/client'

/**
 *
 * @param {object} param0 Root object
 * @param {number} param0.page The number of the page to retrieve from the server
 * @param {number} param0.perPage The number of items in each page
 * @param {string} param0.q The query to search users
 * @param {string[]} param0.roles The list of roles to search for
 */
export async function list ({ page, perPage, q, roles }) {
  const response = await client.get('/users', {
    params: {
      skip: (page - 1) * perPage,
      limit: perPage,
      ...(q && { q }),
      ...(roles && { roles })
    }
  })
  return response.data
}

/**
 *
 * @param {object} param0 Root object
 * @param {string} param0.username The username of the user to get
 */
export async function get ({ username }) {
  const response = await client.get(`/users/${username}`)
  return response.data
}

/**
 * Updates a user
 * @param {object} param0 Root object
 * @param {string} param0.username The username of the user to update
 * @param {string[]} param0.roles The list of roles to give the user
 */
export async function updateRoles ({ username, roles }) {
  const response = await client.put(`/users/${username}`, { roles })
  return response.data
}

export default {
  list,
  get,
  updateRoles
}
